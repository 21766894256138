.react-photo-album.react-photo-album--columns {
    .react-photo-album--column {
        width: calc(100% / 4 - 10rem * 3 / 4) !important;
        justify-content: flex-start !important;
    }

}

.creatives {

    &-album {
        border-radius: 16rem;
        background: var(--white, #FFF);
        padding: 24rem 23rem;
    }
    &-item {
        border-radius: 12rem;
        overflow: hidden;
        background: #F2F4F6;
        transition: 0.3s;
        margin-bottom: 11rem;
        cursor: pointer;
        width: 100%;

        &:hover {
            position: relative;
            z-index: 1;
            border-radius: 12rem;
            background: #F2F4F6;
            box-shadow: 0rem 4rem 70rem 0rem #B4BCC6;
        }

        &-top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 12rem 16rem;
            border-bottom: 1rem solid #E5E8EC;
            margin-bottom: 16rem;
        }

        &__title {
            color: #000;
            font-family: Italian Plate No2 Expanded, sans-serif;
            font-size: 16rem;
            font-style: normal;
            font-weight: 600;
            line-height: 130%;
            /* 20.8rem */
            padding: 0 16rem;
            margin-bottom: 16rem;
        }

        &__date {
            color: #A7A8AA;
            font-family: Italian Plate No2 Expanded, sans-serif;
            font-size: 14rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            padding: 0 16rem;
            margin-bottom: 24rem;
        }

        &__img {
            position: relative;
        }

        &__ai {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 141rem;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.00) 100%);
            padding: 8rem;

            span {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 6rem;
                height: 28rem;
                border-radius: 19rem;
                background: var(--white, #FFF);
                color: #000;
                font-family: Italian Plate No2 Expanded, sans-serif;
                font-size: 14rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                width: min-content;
                white-space: nowrap;

                svg {
                    width: 16rem;
                    height: 16rem;
                    margin-right: 6rem;
                }

            }
        }

        img {
            margin-bottom: 0 !important;
        }

        // &-info {

        // }

        &__social {
            color: #000;
            font-family: Italian Plate No2 Expanded, sans-serif;
            font-size: 14rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        &__size {
            color: #A7A8AA;
            text-align: right;
            font-family: Italian Plate No2 Expanded, sans-serif;
            font-size: 14rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }

    &-modal {
        &-close {
            position: absolute;
            top: -36rem;
            right: -36rem;
            cursor: pointer;

            svg {
                width: 36rem;
                height: 36rem;
            }
        }

        &__img {
            img {
                width: 100%;
                border-radius: 16rem;
            }
        }

    }

    &-block {
        border-radius: 16rem;
        background: var(--white, #FFF);
        padding: 32rem 32rem 36rem;
        display: flex;
        margin-bottom: 40rem;
        &-item {
            width: 160rem;

            span {
                display: block;
                color: #A7A8AA;
                font-family: Italian Plate No2 Expanded, sans-serif;
                font-size: 14rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-bottom: 10rem;
            }

            strong {
                color: #000;
                font-family: Italian Plate No2 Expanded, sans-serif;
                font-size: 24rem;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
        }
    }
}