.promotionsPage {
    width: 100%;

    &-loader {
        display: block;
        position: relative;
        height: 600rem;

        .pin-spacer {
            height: 100% !important;
        }

        .ios-loader {
            width: 26rem !important;
            height: 26rem !important;
            top: 50% !important;
            left: 50% !important;
            margin-left: -13rem !important;
            margin-top: -13rem !important;
            padding: 0 !important;
        }

        .ios-loader .bar {
            background: #8f969c;
        }
    }

    &-nav {
        display: flex;

        a {
            height: 35rem;
            padding: 0 14rem;
            background: transparent;
            display: flex;
            align-items: center;
            color: #A7A8AA;
            font-weight: 500;
            line-height: 1.3;
            transition: 0.3s;
            margin-right: 6rem;
            font-size: 16rem;

            &:hover,
            &.active {
                background: #fff;
                border-radius: 8rem;
                color: #000;
            }

        }
    }

    background: #FFFFFF;
    border-radius: 16rem;

    &-top {
        padding: 24rem 24rem 16rem;
        border-bottom: 1rem solid #F2F4F6;
        display: grid;
        grid-template-columns: 648rem 1fr;
        grid-gap: 32rem;
        color: #A7A8AA;
        font-size: 16rem;
        font-weight: 500;
        line-height: 1;

        &__btn {
            width: 34rem;

            height: 34rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1rem solid #F2F4F6;
            border-radius: 10rem;
            cursor: pointer;
            transition: 0.3s;

            &:hover {
                background: #F2F4F6;
            }
        }

        &-right {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    &-content {
        display: grid;
        grid-template-columns: 648rem 1fr;
        grid-gap: 32rem;
        padding: 24rem 24rem 32rem;
    }

    &-creatives {
        display: grid;
        grid-template-columns: 354rem 1fr;
        grid-gap: 24rem;
        grid-row-gap: 23rem;

        &-img {

            overflow: hidden;

            &:nth-child(1) {
                grid-row: span 2;
            }

            position: relative;

            img {
                border-radius: 16rem;
                width: 100%;

            }
        }


    }

    &-info {
        padding-top: 32rem;
        display: flex;
        flex-direction: column;

        &-items {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-column-gap: 40rem;
            grid-row-gap: 50rem;
        }

        &-action {
            display: flex;
            flex: 1;
            align-items: flex-end;
            justify-content: flex-end;

            &__clear {
                height: 61rem;
                padding: 0 24rem;
                display: flex;
                align-items: center;
                cursor: pointer;
                font-size: 16rem;
                color: #A7A8AA;
                font-weight: 600;
                margin-right: 10rem;
                transition: 0.3s;

                &:hover {
                    color: #000000;
                }
            }

            &__edit {
                height: 61rem;
                padding: 0 24rem;
                width: 115rem;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                font-size: 16rem;
                color: #000;
                font-weight: 600;
                margin-right: 10rem;
                border: 1rem solid #F2F4F6;
                border-radius: 66rem;
                transition: 0.3s;
                background: transparent;
                margin-right: auto;

                &:hover {
                    background: #F2F4F6;

                }

                svg {
                    width: 18rem;
                    height: 18rem;
                    margin-right: 8rem;
                    pointer-events: none;
                }
            }

            &__reject {
                height: 61rem;
                padding: 0 24rem;
                width: 160rem;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                font-size: 16rem;
                color: #F43535;
                font-weight: 600;
                margin-right: 10rem;
                border: 1rem solid #F43535;
                border-radius: 66rem;
                transition: 0.3s;
                background: transparent;

                &:hover {
                    background: #F43535;
                    color: #fff;
                }
            }

            &__approve {
                height: 61rem;
                padding: 0 24rem;
                width: 160rem;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                font-size: 16rem;
                color: #FFF;
                font-weight: 600;
                margin-right: 10rem;
                border-radius: 66rem;
                background: #17CC37;
                transition: 0.3s;

                &:hover {
                    background: #109629;
                }
            }

            &-text {
                border: 1rem solid #EF2C4F;
                padding: 16rem;
                border-radius: 10rem;
                background: #FEF4F6;

                &.green {
                    background: #EFFDF6;
                    border: 1rem solid #17CC37;
                }

                span {
                    display: flex;
                    align-items: center;
                    margin-top: 8rem;
                    font-size: 16rem;
                    line-height: 1.55;
                    font-weight: 400;
                    color: #000;

                    i {
                        font-size: 14rem;
                        line-height: 1.55;
                        font-weight: 600;
                        color: #000;
                        font-style: normal;
                        text-decoration: underline;
                        margin-left: 4rem;
                        cursor: pointer;
                    }

                }
            }

            p {
                font-size: 16rem;
                line-height: 1.3;
                font-weight: 600;
            }
        }
    }

    .table {
        .MuiTableCell-root {
            span {
                white-space: nowrap;
                text-overflow: ellipsis;
                display: block;
                overflow: hidden;
            }
        }
    }

    .loading {
        display: flex;
        pointer-events: none;

        i {
            animation-direction: alternate;
            animation-duration: 0.5s;
            animation-fill-mode: none;
            animation-iteration-count: infinite;
            animation-name: stretch;
            animation-play-state: running;
            animation-timing-function: ease-out;
            border-radius: 100%;
            display: block;
            height: 10rem;
            width: 10rem;
            animation-delay: 0.1s;
            margin: 0 5rem;

            &:first-child {
                animation-delay: 0s;
                margin: 0;
            }

            &:last-child {
                animation-delay: 0.2s;
                margin: 0;
            }
        }
    }

    &-errorPupup {
        background: #FFF;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 60rem 80rem 60rem;
        border-radius: 24rem;

        svg {
            margin-bottom: 24rem;
        }

        &__title {
            font-size: 28rem;
            line-height: 1.25;
            color: #000;
            font-weight: 700;
            margin-bottom: 24rem;
            text-align: center;
        }

        &__text {
            font-size: 16rem;
            line-height: 1.25;
            color: #000;
            font-weight: 400;
            text-align: center;
            background: #FEF4F6;
            border: 1rem solid #EF2C4F;
            border-radius: 10rem;
            padding: 16rem;
        }

        &-close {
            top: 16rem;
            right: 16rem;
            position: absolute;
            cursor: pointer;

            svg {
                width: 24rem;
                height: 24rem;
            }
        }

        label {
            display: block;
            width: 100%;
            margin-bottom: 24rem;

            span {
                font-size: 14rem;
                color: #A7A8AA;
                font-weight: 600;
                line-height: 1.2;
                margin-bottom: 10rem;
                display: block;
            }

            p {
                padding: 15rem 16rem;
                border: 1rem solid #F2F4F6;
                border-radius: 9rem;
                font-size: 16rem;
                color: #000;
                font-weight: 400;
                line-height: 1.2;
            }
        }

        &__textarea {
            position: relative;

            textarea {
                width: 100%;
                height: auto;
                resize: none;
                min-height: 75rem;
                padding: 15rem 16rem 41rem;
                outline: none;
                border: 1rem solid #F2F4F6;
                border-radius: 9rem;
                font-size: 16rem;
                color: #000;
                font-weight: normal;
                overflow-y: 'hidden',
            }

            i {
                position: absolute;
                bottom: 15rem;
                right: 16rem;
                font-size: 14rem;
                color: #000;
                font-weight: normal;
                font-style: normal;
            }
        }

        &__cancel {
            height: 61rem;
            padding: 0 24rem;
            width: 160rem;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            font-size: 16rem;
            color: #000;
            font-weight: 600;
            margin-right: 10rem;
            border: 1rem solid #F2F4F6;
            border-radius: 66rem;
            margin-right: 10rem;
            transition: 0.3s;
            background: transparent;

            &:hover {
                background: #F2F4F6;
            }
        }

        &__confirm {
            height: 61rem;
            padding: 0 24rem;
            width: 160rem;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            font-size: 16rem;
            color: #FFF;
            font-weight: 600;
            margin-right: 10rem;
            border-radius: 66rem;
            background: #17CC37;
            transition: 0.3s;

            &:hover {
                background: #109629;
            }
        }

        &__reject {
            height: 61rem;
            padding: 0 24rem;
            width: 160rem;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            font-size: 16rem;
            color: #fff;
            font-weight: 600;
            margin-right: 10rem;
            background: #F43535;
            border-radius: 66rem;
            transition: 0.3s;

            &:hover {
                background: #CC2C2C;
            }
        }

        input {
            width: 100%;
            border: 1px solid #f2f4f6;
            border-radius: 9rem;
            padding: 0rem 16rem;

            height: 46rem;
            font-family: "Italian Plate No2 Expanded", sans-serif;
            font-weight: 500;
            font-size: 16rem;
            color: #000;
        }

        &-action {
            display: flex;
            justify-content: center;
            margin-top: 32rem;
        }

        .loading {
            display: flex;
            pointer-events: none;

            i {
                animation-direction: alternate;
                animation-duration: 0.5s;
                animation-fill-mode: none;
                animation-iteration-count: infinite;
                animation-name: stretch;
                animation-play-state: running;
                animation-timing-function: ease-out;
                border-radius: 100%;
                display: block;
                height: 10rem;
                width: 10rem;
                animation-delay: 0.1s;
                margin: 0 5rem;

                &:first-child {
                    animation-delay: 0s;
                    margin: 0;
                }

                &:last-child {
                    animation-delay: 0.2s;
                    margin: 0;
                }
            }
        }
    }

    &-empty {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 300rem 0;
        font-size: 28rem;
        font-weight: 700;
        line-height: 1.2;
        color: #A7A8AA;
    }

    &New {
        position: relative;

        .table {
            overflow: visible;
        }

        display: flex;
        flex-direction: column;
        height: 100%;
        max-height: 100%;

        &-top {
            padding: 24rem;
            display: flex;
            justify-content: space-between;
            padding-bottom: 0rem;
            border-bottom: 1rem solid #F2F4F6;
            top: 0;
            z-index: 2;
            background: #fff;
            border-radius: 16rem 16rem 0 0;

            .userInfo-block-item {
                margin-right: 0rem;
                position: relative;
                padding-bottom: 40rem;

                .table-tooltip-info {
                    // display: flex;
                    white-space: nowrap;
                    top: auto;
                    bottom: -0rem;

                    &:after {
                        bottom: calc(100% - 2rem);
                        top: auto;
                        transform: translateX(-50%) rotate(180deg);
                    }
                }

                &--location {
                    .table-tooltip-info {
                        right: 0;
                        left: auto;
                        transform: translateX(0);
                        height: auto;
                        padding-top: 4rem;
                        padding-bottom: 4rem;
                        max-width: 500rem;
                        bottom: auto;
                        top: 60rem;

                        &:after {
                            right: 80rem;
                            left: auto;
                            transform: translateX(0%) rotate(180deg);
                        }

                        span {
                            white-space: break-spaces;
                        }
                    }

                    .table-tooltip-info__btn {
                        height: 100%;
                    }

                }

                &:hover .table-tooltip-info {
                    display: flex;
                }

                p {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;

                }
            }
        }

        &-bottom {
            display: flex;
            justify-content: flex-end;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            background: #fff;
            border-top: 1rem solid #f2f4f6;
            padding: 10rem 24rem;
        }


        &-content {
            padding: 0rem 24rem 40rem;
            flex: 1;
            overflow: auto;
            display: flex;
            flex-direction: column;

            &::-webkit-scrollbar {
                width: 4rem;
                height: 4rem;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 10rem;
                background: rgba(213, 218, 224, 0.87);
            }


            &-item {
                display: grid;
                grid-template-columns: 1126rem 1fr;
                grid-column-gap: 40rem;
                grid-row-gap: 32rem;
                border-bottom: 1rem solid #F2F4F6;
                padding-top: 40rem;
                padding-bottom: 40rem;

                &:nth-child(3) {
                    border: none;
                }

                &__title {
                    grid-column: span 2;
                    font-family: "Italian Plate No2 Expanded", sans-serif;
                    font-weight: 600;
                    font-size: 18rem;
                    color: #8f969c;
                }
            }
        }

        &-creatives {
            display: grid;
            grid-template-columns: 463rem 376rem 263rem;
            grid-gap: 12rem;

            &-img {
                // height: 100%;
                position: relative;

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border-radius: 16rem;
                    object-fit: cover;
                }

            }

            &-video {
                position: relative;
                border-radius: 16rem;

                svg {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 1;
                    pointer-events: none;
                }

                &:after {
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 100%;
                    background: rgba(#000000, .22);
                    pointer-events: none;
                }
            }
        }

        &-info {
            padding-top: 30rem;

            &-top {
                display: flex;
                align-items: center;
                margin-bottom: 32rem;

                &__name {
                    strong {
                        display: block;
                        font-family: "Italian Plate No2 Expanded", sans-serif;
                        font-weight: 600;
                        font-size: 16rem;
                        line-height: 1;
                        color: #000;
                        margin-bottom: 6rem;

                    }

                    span {
                        display: flex;
                        align-items: center;
                        font-family: "Italian Plate No2 Expanded", sans-serif;
                        font-weight: 400;
                        font-size: 12rem;
                        line-height: 1;
                        color: #cad4dd;

                        svg {
                            margin-left: 4rem;
                        }
                    }
                }
            }

            &__ava {
                width: 40rem;
                min-width: 40rem;
                height: 40rem;
                border-radius: 50%;
                background: #D182D3;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: "Italian Plate No2 Expanded", sans-serif;
                font-weight: 600;
                font-size: 16rem;
                line-height: 1;
                color: #fff;
                margin-right: 10rem;
            }

            &-text {
                margin-bottom: 24rem;

                span {
                    font-family: "Italian Plate No2 Expanded", sans-serif;
                    font-weight: 500;
                    font-size: 14rem;
                    color: #a7a8aa;
                    line-height: 1;
                    display: block;
                    margin-bottom: 12rem;
                }

                p {
                    font-family: "Italian Plate No2 Expanded", sans-serif;
                    font-weight: 500;
                    font-size: 16rem;
                    line-height: 130%;
                    color: #000;
                }
            }

            &__btn {
                background: #f2f4f6;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 44rem;
                padding: 12rem 22rem;
                font-family: "Italian Plate No2 Expanded", sans-serif;
                font-weight: 500;
                font-size: 16rem;
                line-height: 130%;
                color: #000;
                width: min-content;
                white-space: nowrap;
            }
        }
    }

    &-date-wrap {
        .react-datepicker {
            .react-datepicker__navigation--previous {
                left: 0;
            }

            .react-datepicker__navigation--next {
                right: 0;

            }

            .react-datepicker__current-month {
                font-family: "Italian Plate No2 Expanded", sans-serif;
                font-weight: 600;
                font-size: 18rem;
                line-height: 1;
                color: #000;
                margin-bottom: 26rem;
            }

            .react-datepicker__day-name {
                font-family: "SF Pro Text", sans-serif;
                font-weight: 600;
                font-size: 13rem;
                line-height: 138%;
                text-transform: uppercase;
                text-align: center;
                color: rgba(60, 60, 67, 0.3);
                width: 40rem;
            }

            .react-datepicker__header {
                padding-bottom: 0;
                border: none;
                margin-bottom: 16rem;
            }

            .react-datepicker__day {
                font-family: "SF Pro Display", sans-serif;
                font-weight: 400;
                font-size: 16rem;
                line-height: 120%;
                text-transform: uppercase;
                text-align: center;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                width: 40rem;
                height: 40rem;
            }

            .react-datepicker__day--selecting-range-start {
                border-radius: 20rem 0 0 20rem;
            }

            .react-datepicker__day--selecting-range-start {
                border-radius: 0 20rem 20rem 0;
            }

            .react-datepicker__day--range-start {
                border-top-left-radius: 20rem !important;
                border-bottom-left-radius: 20rem !important;
            }

            .react-datepicker__day--range-end {
                border-top-right-radius: 20rem !important;
                border-bottom-right-radius: 20rem !important;
            }
        }

    }
}

// .pin-spacer {
//     padding: 0!important;
//     height: 0!important;
// }
@keyframes stretch {
    0% {
        transform: scale(0.5);
        background-color: #fff;
    }

    50% {
        background-color: #fff;
    }

    100% {
        transform: scale(1);
        background-color: #ffffff;
    }
}

.fancybox__container {
    z-index: 10000 !important;
}

body .fancybox__slide {
    display: flex;
    align-items: center;
    flex-direction: row;

    .fancybox__content {
        height: 700rem !important;
        width: auto !important;
        aspect-ratio: auto !important;

        img {
            width: auto;
            height: 700rem;
        }
    }



    .fancybox__caption {
        width: 502rem;
        padding: 0 40rem;
        height: 700rem;
        background: #fff;
        margin-left: -1rem;
    }

    .fancybox__html5video {
        aspect-ratio: 9/16;
        width: auto;
        height: 100%;
    }
}