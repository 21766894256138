.admins {

    &-action {
        display: grid;
        grid-template-columns: 563rem 563rem;
        grid-gap: 16rem;
        margin-bottom: 40rem;

        &-item {
            height: 134rem;
            display: flex;

            background: #fff;
            border-radius: 16rem;
            padding: 24rem;

            svg {
                width: 32rem;
                height: 32rem;
                margin-right: 16rem;
            }

            &__title {
                font-family: "Italian Plate No2 Expanded", sans-serif;
                font-weight: 600;
                font-size: 22rem;
                line-height: 145%;
                color: #000;
                margin-bottom: 16rem;
            }

            p {
                font-family: "Italian Plate No2 Expanded", sans-serif;
                font-weight: 500;
                font-size: 16rem;
                line-height: 120%;
                color: #83868c;
            }
        }
    }

}

.admin-table_img {
    width: 52rem;
    height: 52rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    font-size: 16rem;
    font-weight: 600;
    border-radius: 50%;
    background: #262626;
}

.admins-sidebar {
    padding: 31rem 40rem;

    &-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: "Italian Plate No2 Expanded", sans-serif;
        font-weight: 400;
        font-size: 16rem;
        color: #000;
        margin-bottom: 42rem;

        &-right {
            display: flex;
            align-items: center;
        }

        &-active {
            display: flex;
            align-items: center;
            font-family: "Italian Plate No2 Expanded", sans-serif;
            font-weight: 400;
            font-size: 14rem;
            color: #000;

            input {
                width: 0;
                height: 0;
                position: absolute;

                &:checked+.admins-sidebar-top-checkbox {
                    background: #000;
                    span {
                        transform: translateX(16rem);
                    }
                }
            }

        }

        &-checkbox {
            width: 40rem;
            height: 24rem;
        
            background: #e4e8ed;
            border-radius: 100rem;
            display: flex;
            align-items: center;
            padding: 0 2rem;
            margin-left: 10rem;
            cursor: pointer;
            span {
                width: 20rem;
                height: 20rem;
                background: #fff;
                border-radius: 50%;
                transition: 0.3s;
            }
        }

        ul {
            display: flex;

            li {
                border-radius: 10rem;
                background: var(--white, #FFF);
                width: 38rem;
                height: 38rem;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                margin-left: 10rem;
                position: relative;

                transition: box-shadow 0.3s;

                svg {
                    width: 20rem;
                    height: 20rem;
                }

                &:hover {
                    box-shadow: 0rem 7rem 52rem -2rem rgba(0, 0, 0, 0.12);

                    span {
                        display: flex;
                    }
                }

                &.delete {
                    background: #F43535;
                    transition: background 0.3s;

                    &:hover {
                        background: #CC2C2C;

                        span {
                            right: 0;

                            &::after {
                                right: 10rem;
                            }
                        }
                    }
                }

                span {
                    position: absolute;
                    top: calc(100% + 12rem);
                    height: 28rem;
                    padding: 0 12rem;
                    display: none;
                    align-items: center;
                    background: #000;
                    color: #FFF;
                    text-overflow: ellipsis;
                    font-family: Italian Plate No2 Expanded;
                    font-size: 14rem;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                    white-space: nowrap;
                    border-radius: 5rem;
                    right: -13rem;

                    &::after {

                        display: inline-block;
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 0 9rem 10rem 9rem;
                        border-color: transparent transparent #000 transparent;
                        position: absolute;
                        content: '';
                        bottom: 100%;
                        right: 23rem;
                    }
                }

            }
        }
    }

    &-info {
        background: #fff;
        border-radius: 16rem;
        padding: 36rem 32rem;
        margin-bottom: 50rem;

        &__title {
            font-family: "Italian Plate No2 Expanded", sans-serif;
            font-weight: 600;
            font-size: 24rem;
            color: #000;
            margin-bottom: 40rem;
        }

        &-wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr 148rem;
            grid-column-gap: 32rem;
            grid-row-gap: 26rem;
            max-width: 100%;
        }

        .input {
            position: relative;
            display: flex;
            align-items: flex-start;
        }

        &__input {
            span {
                font-family: "Italian Plate No2 Expanded", sans-serif;
                font-weight: 600;
                font-size: 14rem;
                color: #a7a8aa;
                display: block;
                margin-bottom: 10rem;
                width: 100%;

            }

            i {
                position: absolute;
                right: 16rem;
                top: 50%;
                transform: translateY(-50%);
                cursor: pointer;

                svg {
                    width: 20rem;
                    height: 14rem;
                }
            }

            input {
                width: 100%;
                border: 1px solid #f2f4f6;
                border-radius: 9rem;
                padding: 0rem 16rem;

                height: 46rem;
                font-family: "Italian Plate No2 Expanded", sans-serif;
                font-weight: 500;
                font-size: 16rem;
                color: #000;
            }

            &--file {
                grid-column-start: 3;
                grid-row-start: 1;
                grid-row-end: 3;

                input {
                    width: 0;
                    height: 0;
                    opacity: 0;
                    position: absolute;
                }

                svg {
                    width: 148rem;
                    height: 148rem;

                }

                img {
                    width: 148rem;
                    height: 148rem;
                    object-fit: cover;
                    border-radius: 11rem;
                    border: 1px solid #f2f4f6;
                }
            }
        }
    }

    &-role {
        background: #fff;
        border-radius: 16rem;
        padding: 36rem 32rem;

        &__title {
            font-family: "Italian Plate No2 Expanded", sans-serif;
            font-weight: 600;
            font-size: 24rem;
            color: #000;
            margin-bottom: 40rem;
        }

        &-top {


            margin-bottom: 32rem;

            >span {
                font-family: "Italian Plate No2 Expanded", sans-serif;
                font-weight: 600;
                font-size: 14rem;
                color: #a7a8aa;
                margin-bottom: 10rem;
                display: block;
            }

        }

        &-wrapper {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 32rem;
        }

        &-item {
            &__title {
                font-family: "Italian Plate No2 Expanded", sans-serif;
                font-weight: 600;
                font-size: 14rem;
                color: #a7a8aa;
                margin-bottom: 24rem;
            }

            label {
                display: flex;
                align-items: center;
                margin-bottom: 24rem;

                &:last-child {
                    margin-bottom: 0;
                }

                input {
                    width: 0;
                    height: 0;
                    opacity: 0;
                    position: absolute;

                    &:checked {
                        ~.admins-sidebar-role-item__icon {
                            background: #000;
                            border: 1px solid #000;
                        }
                    }
                }

            }

            &__icon {
                border: 1px solid #cad4dd;
                border-radius: 5rem;
                width: 20rem;
                height: 20rem;
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: 20rem;
                margin-right: 10rem;

                svg {
                    width: 11rem;
                    height: 8rem;
                }
            }

            span {
                font-family: "Italian Plate No2 Expanded", sans-serif;
                font-weight: 500;
                font-size: 14rem;
                color: #404040;
            }
        }
    }

    &__btn {
        width: 158rem;
        height: 61rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        background: #000;
        border-radius: 66rem;
        padding: 20rem 54rem;
        width: 158rem;
        height: 61rem;
        margin-top: 20rem;
        font-weight: 600;
        font-size: 16rem;
        line-height: 130%;
        text-align: center;
        color: #fff;
        cursor: pointer;
        transition: 0.3s;

        &:hover {
            border: 1rem solid var(--black, #000);
            background: #fff;
            color: #000;
        }

        &.disable {
            background: #e4e8ed;
            color: #8f969c;
            pointer-events: none;
        }
    }

}

.modal-admins-password {
    width: 100%;

    .admins-sidebar-info__input {
        width: 100%;
        margin-bottom: 32rem;

    }

    .input {
        position: relative;
    }
}