.modal {
    &-invoice {
        overflow-y: auto;
        padding: 40rem 0;
        .modal-container {
            margin: 0 auto;
            position: relative;
        }
        .modal-action {
            margin-left: auto;
            margin-right: 0;
            justify-content: flex-end;
        }
        .modal__send {
            width: 200rem;
        }
    }
}
.invoice {
    width: 1240rem;
    background: #fff;
    margin: 0 auto;
    padding: 115rem 110rem;
    &-inputs {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10rem;
        margin-bottom: 10rem;
    }
    .input {
        span {
            font-size: 16rem;
            font-weight: 600;
            margin-bottom: 5rem;
            display: block;
        }
    }
    input {
        width: 100%;
        border: 1rem solid #f2f4f6;
        border-radius: 9rem;
        padding: 0rem 16rem;
    
        height: 46rem;
        font-family: "Italian Plate No2 Expanded", sans-serif;
        font-weight: 500;
        font-size: 16rem;
        color: #000;
    }
}

.invoice-top {
    width: 100%;
    display: flex;
    border-bottom: 1rem solid #ededed;
    padding-bottom: 20rem;
    margin-bottom: 25rem;
}

.invoice-top-logo {
    width: 180rem;
    margin-right: 40rem;
}

.invoice-top-logo img {
    width: 100%;
}

.invoice-top-center h2 {
    font-size: 32rem;
    font-weight: 700;
    line-height: 1.5;
    margin: 0 0 10rem;
}

.invoice-top-center p {
    font-size: 18rem;
    font-weight: 400;
    line-height: 1.5;
    margin: 0 0 8rem;
}

.invoice-top-right {
    margin-left: auto;
    padding-top: 10rem;
}

.invoice-top-right p {
    text-align: right;
    font-size: 18rem;
    line-height: 1.5;
    margin: 0 0 14rem;
    font-weight: 500;
}

.invoice-top-right p span {
    display: block;
    font-weight: 14rem;
}

.invoice-middle {
    margin-bottom: 30rem;
}

.invoice-middle h3 {
    margin: 0 0 18rem;
    font-size: 24rem;
    font-weight: 600;
}

.invoice-middle h4 {
    margin: 0 0 10rem;
    font-size: 32rem;
    font-weight: 600;
}

.invoice-middle p {
    margin: 0 0 8rem;
    font-size: 26rem;
    font-weight: 400;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 40rem;
}

thead td {
    border-top: 2rem solid #000;
    border-bottom: 2rem solid #000;
    padding-top: 15rem;
    padding-bottom: 15rem;
    font-size: 22rem;
    font-weight: 700;
    line-height: 1.5;
}

thead td:first-child {
    width: 540rem;
}

thead td:nth-child(2) {
    width: 220rem;
}

thead td:nth-child(3) {
    width: 50rem;
    text-align: center;
}

thead td:last-child {
    text-align: right;
}

tbody td {
    border-bottom: 2rem solid #edeeed;
    padding-top: 15rem;
    padding-bottom: 15rem;
    font-size: 20rem;
    font-weight: 500;
    line-height: 1.5;
}

tbody td:nth-child(3) {
    text-align: center;
}

tbody td:last-child {
    text-align: right;
}

.invoice-bottom {
    width: 570rem;
    margin-left: auto;
}

.invoice-bottom p {
    margin: 0 0 25rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.invoice-bottom p span {
    font-size: 22rem;
    font-weight: 600;
    line-height: 1.2;
}

.invoice-bottom p strong {
    font-size: 32rem;
    font-weight: 700;
    line-height: 1.2;
}

.invoice-bottom p.border {
    border-top: 2rem solid #edeeed;
    border-bottom: 2rem solid #edeeed;
    padding-top: 25rem;
    padding-bottom: 25rem;
}

.invoice-bottom p.border:last-child {
    border-top: none;
    padding-top: 0;
}