.products-images {
    height: 100%;

    &__title {
        font-weight: 500;
        font-size: 14rem;
        color: #a7a8aa;
        margin-bottom: 20rem;
    }

    &-wrapper {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 12rem;
        margin-bottom: 24rem;
    }

    &__img {
        img {
            border: 1px solid #f2f4f6;
            border-radius: 12rem;
            width: 151rem;
            height: 151rem;
            object-fit: cover;
        }
    }

    &__video {
        position: relative;

        svg {
            position: absolute;
            width: 20rem;
            height: 20rem;
            top: 8rem;
            left: 8rem;
        }

        span {
            font-weight: 600;
            font-size: 15px;
            color: #fff;
            position: absolute;
            right: 12rem;
            bottom: 12rem;
            z-index: 1;
        }

        &:after {
            position: absolute;
            content: '';
            width: 100%;
            height: 82rem;
            bottom: 0;
            left: 0;
            border-radius: 0 0 12rem 12rem;
            background: linear-gradient(167deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
        }
    }
}