.promotions-sidebar {
    width: 809rem;
    &-wrapper {
        padding-top: 87rem;
    }
    &-filter {
        padding: 24rem;
        background: #fff;
        border-radius: 16rem;
        margin-bottom: 24rem;
    }
    &__title {
        font-weight: 600;
        font-size: 20rem;
        color: #a7a8aa;
    }
    .table-top {
        justify-content: space-between;
    }
    .MuiTableCell-root {
        border-right: none;
    }
    &-table-row {
        display: flex;
        align-items: center;
        .green {
            background: #17cc37;
            width: 8rem;
            height: 8rem;
            border-radius: 50%;
            margin-right: 7rem;
        }
        .red {
            background:  #f43535;
            width: 8rem;
            height: 8rem;
            border-radius: 50%;
            margin-right: 7rem;
        }
        font-weight: 400;
        font-size: 16rem;
        color: #000;
        svg {
            margin-left: 7rem;
            width: 16rem;
            height: 16rem;
        }
    }
    .pagination {
        margin-top: 24rem;
        justify-content: flex-end;
    }
}