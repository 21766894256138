.profile {
    margin-left: auto;
  
    position: relative;
    &-wrap {
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    &-name {
        color: #fff;
        text-align: right;
        font-size: 16rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-right: 15rem;
    }
    &-ava {
        width: 50rem;
        height: 50rem;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
            border: 1rem solid #363636;
        }
        color: #FFFFFF;
        font-size: 16rem;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: #262626;
    }
    &-logout {
        position: absolute;
        right: 0;
        top: calc(100% + 6rem);
        border-radius: 8rem;
        border: 1rem solid #F2F4F6;
        background: var(--white, #FFF);
        box-shadow: 0rem 7rem 41rem -6rem rgba(0, 0, 0, 0.08);
        display: flex;
        align-items: center;
        padding: 12rem 16rem;
        color: #F43535;
        text-align: center;
        font-size: 16rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        cursor: pointer;
        svg {
            width: 18rem;
            height: 18rem;
            margin-left: 10rem;
        }
    }
}