.simplepicker-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    overflow: auto;
    background-color: #4c4c4c7d;
    width: 100vw;
    height: 100vh;
    display: none;
    z-index: 1000;
}

.simplepicker-wrapper.active {
    display: block
}

.simpilepicker-date-picker {
    width: 312rem;
    cursor: auto;
    font-family: Italian Plate No2 Expanded, sans-serif;
    font-size: 18rem;
    background-color: #fff;
    margin: 2% auto;
    border-radius: 12rem;
    overflow: hidden;
    line-height: 1;
}

.simplepicker-day-header {
    color: #fff;
    font-size: 15rem;
    background: #000;
    text-align: center;
    padding: 7rem 0;
    border-bottom: 1rem solid #1F1F1F;
}

.simplepicker-date-section {
    text-align: center;
    background-color: #000;
    color: #fff
}

.simplepicker-date-section .simplepicker-month-and-year {
    font-size: 18rem;
    padding: 14rem 0 6rem;
    font-weight: 600;
    color: #1F1F1F;
}

.simplepicker-date-section .simplepicker-date {
    padding: 6rem 0 17rem;
    font-size: 35rem;
    font-weight: 600;
}

.simplepicker-select-pane {
    display: flex;
    width: 100%
}

.simplepicker-select-pane .simplepicker-time,
.simplepicker-selected-date {
    text-align: center;
    flex: 2;
    font-size: 15rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.simplepicker-icon,
.simplepicker-selected-date,
.simplepicker-time {
    border: none;
    background: transparent;
    padding: 0rem;
    margin: 0rem 10rem 10rem;
    width: 34rem;
    height: 34rem;
    opacity: .6;
    background-size: cover;
}

.simplepicker-selected-date,
.simplepicker-time {
    opacity: 1
}

.simplepicker-month-switcher {
    margin-top: 5rem
}

.simplepicker-icon:hover {
    opacity: .8
}

.simplepicker-icon.active,
.simplepicker-icon:active {
    opacity: 1
}

.simplepicker-icon:focus {
    outline: none
}

.simplepicker-icon-next {
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 13L11 8L6 3' stroke='black' stroke-width='1.9' stroke-linecap='round'/%3E%3C/svg%3E%0A");
    background-size: 16rem 16rem;
    background-position: center;
    background-repeat: no-repeat;
}

.simplepicker-icon-previous {
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 13L5 8L10 3' stroke='black' stroke-width='1.9' stroke-linecap='round'/%3E%3C/svg%3E%0A");
    background-size: 16rem 16rem;
    background-position: center;
    background-repeat: no-repeat;
}

.simplepicker-icon-calender {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='34' height='34' viewBox='0 0 34 34' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M24.5771 12.1581H9.41486C8.48443 12.1581 7.73017 12.9124 7.73017 13.8428V24.7933C7.73017 25.7238 8.48443 26.478 9.41486 26.478H24.5771C25.5075 26.478 26.2618 25.7238 26.2618 24.7933V13.8428C26.2618 12.9124 25.5075 12.1581 24.5771 12.1581ZM9.41486 6.26172C7.08878 6.26172 5.20312 8.14738 5.20312 10.4735V24.7933C5.20312 27.1194 7.08878 29.0051 9.41486 29.0051H24.5771C26.9032 29.0051 28.7888 27.1194 28.7888 24.7933V10.4735C28.7888 8.14738 26.9032 6.26172 24.5771 6.26172H9.41486Z' fill='white'/%3E%3Crect x='9.41406' y='5' width='2.52704' height='3.36939' rx='1.26352' fill='white'/%3E%3Crect x='22.0508' y='5' width='2.52704' height='3.36939' rx='1.26352' fill='white'/%3E%3C/svg%3E");
}

.simplepicker-icon-time {
    background-image: url("data:image/svg+xml,%3Csvg width='34' height='34' viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='17' cy='17' r='11' stroke='white' stroke-width='2'/%3E%3Cpath d='M17 9V17.5H24' stroke='white' stroke-width='1.5'/%3E%3Ccircle cx='17' cy='17.5' r='1.5' fill='white'/%3E%3C/svg%3E%0A");
}

.simplepicker-bottom-part {
    display: flex;
    justify-content: flex-end;
    padding: 0 9rem 33rem;

}

.simplepicker-calender table {
    width: 100%;
    font-size: 13rem
}

.simplepicker-calender thead th {
    font-weight: 600;
    font-size: 13rem;
    padding-bottom: 17rem;
    width: 14.28%;
    height: auto;
    text-transform: uppercase;
    color: var(--label-tertiary, rgba(60, 60, 67, 0.30));

}

.simplepicker-calender tbody td {
    position: relative;
    text-align: center;
    vertical-align: middle;
    padding: 12rem 7rem;
    font-size: 16rem;
    z-index: 1
}

.simplepicker-calender {
    padding: 0 9rem;
    margin-bottom: 21rem;
}

.simplepicker-calender tbody td:after {
    content: " ";
    visibility: hidden;
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    height: 40rem;
    width: 40rem;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, .05)
}

.simplepicker-calender tbody td:hover:after {
    visibility: visible
}

.simplepicker-calender tbody td.active,
.simplepicker-calender tbody td:active {
    color: #fff
}

.simplepicker-calender tbody .active:after,
.simplepicker-calender tbody td:active:after {
    visibility: visible;
    background-color: #000;
    z-index: -1
}

.simplepicker-calender tbody td[data-empty] {
    pointer-events: none
}

.simplepicker-time-section {
    display: none;
    height: 120rem
}

.simplepicker-time-section input {
    box-sizing: border-box;
    width: 120rem;
    height: 32rem;
    max-width: 120rem;
    max-height: 32rem;
    padding: 4rem 3rem;
    border: none;
    border-bottom: 2rem solid #ccc;
    font-size: 14rem;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    display: block;
    margin: 25% auto
}

.simplepicker-time-section input:focus {
    outline: none;
    border-bottom-color: teal
}

.simplepicker-picker-section {
    width: 100%;
    height: auto
}

.simplepicker-btn {
    background: transparent;
    padding: 14rem;
    border-radius: 100rem;
    border: 1rem solid #F2F4F6;
    color: #000;
    text-align: center;
    font-size: 16rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.simplepicker-ok-btn {
    background: #000;
    border-color: #000;
    color: #fff;
    margin-left: 8rem;
}

// .simplepicker-btn:active,
// .simplepicker-btn:hover {
//     color: #fff;
//     background-color: #016565
// }

.simplepicker-fade {
    color: hsla(0, 0%, 88%, .95)
}

.simplepicker-btn,
.simplepicker-icon,
.simplepicker-wrapper {
    cursor: pointer
}

.simplepicker-time-section {
    input[type=time] {
        text-align: center;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
    }
}