.login {
    min-height: 100vh;
    background-size: cover;
    display: flex;
    align-items: center;
    width: 100%;

    &-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 171rem 0 358rem;
        width: 100%;
    }

    &-logo {
        svg {
            width: 243rem;
            height: 69rem;
        }
    }

    &-form {
        border-radius: 22rem;
        background: var(--white, #FFF);
        padding: 100rem;
        width: 618rem;
        &__title {
            color: #000;
            text-align: center;
            font-family: Italian Plate No2 Expanded, sans-serif;
            font-size: 42rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-bottom: 40rem;
        }

        label {
            margin-bottom: 40rem;
            display: block;
            width: 100%;

            p {
                color: #000;
                font-family: Italian Plate No2 Expanded, sans-serif;
                font-size: 16rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-bottom: 16rem;
            }

            input {
                width: 100%;
                padding: 0 0 12rem;
                border: none;
                border-bottom: 1rem solid #A7A8AA;
                color: #000;
                font-family: Italian Plate No2 Expanded, sans-serif;
                font-size: 20rem;
                font-style: normal;
                font-weight: 500;
                line-height: 130%;

                &::placeholder {
                    color: #C5C5C5;
                }
            }
        }

        &__btn {
            border-radius: 100rem;
            background: #000;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 64rem;
            color: var(--white, #FFF);
            text-align: center;
            font-family: Italian Plate No2 Expanded, sans-serif;
            font-size: 18rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            cursor: pointer;
            .spin-loader {
                display: none;
            }
            &.active {
                pointer-events: none;
                span {
                    display: none;
                }
                .spin-loader {
                    display: block;
                }
            }
            position: relative;
        }
        .error-message {
            margin-top: 5rem;
            font-size: 14rem;
            color: red;
        }
    }
}