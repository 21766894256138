.promotionsNew-AllPromotions-content {
    overflow-y: auto;
    padding: 32rem 24rem;

    &::-webkit-scrollbar {
        width: 4rem;
        height: 4rem;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10rem;
        background: rgba(213, 218, 224, 0.87);
    }

    &-info {
        display: flex;
        margin-bottom: 40rem;

    }

    &-item {
        margin-right: 98rem;
        padding-right: 98rem;
        display: flex;
        flex-direction: column;
        position: relative;

        &:after {
            width: 1px;
            height: 44rem;
            background: #cad4dd;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            content: '';
            position: absolute;
        }

        &:last-child {
            margin-right: 0;
            padding-right: 0;

            &:after {
                display: none;
            }
        }

        span {
            font-weight: 600;
            font-size: 16rem;
            color: #000;
            line-height: 1;
            margin-bottom: 16rem;
        }

        strong {
            font-weight: 700;
            font-size: 32rem;
            line-height: 1;
            color: #000;
            margin-bottom: 8rem;
        }

        p {
            font-weight: 500;
            font-size: 16rem;
            line-height: 1;
            color: #a7a8aa;
            white-space: nowrap;
        }
    }
}

.promotionsNew-AllPromotions-creatives {
    &-title {
        font-weight: 600;
        font-size: 20rem;
        color: #a7a8aa;
        line-height: 1;
        margin-bottom: 32rem;
    }

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10rem;
}

.promotionsNew-AllPromotions-creative {
    border: 1px solid #f2f4f6;
    border-radius: 24rem;
    padding: 24rem 24rem 32rem 24rem;
    position: relative;
    &-img {
        width: 200rem;
        height: 200rem;
        min-width: 200rem;
        margin-right: 24rem;

        img {
            width: 100%;
            height: 100%;
            border: 1px solid #f2f4f6;
            border-radius: 12rem;
            object-fit: cover;
        }
    }
    &-video {
        position: relative;
        border-radius: 16rem;
        overflow: hidden;
        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
            pointer-events: none;
        }

        &:after {
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            background: rgba(#000000, .22);
            pointer-events: none;
            top: 0;
        }
    }

    &-top {
        display: flex;
        align-items: center;
    }

    &-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &-status {
        background: #effdf6;
        display: flex;
        align-items: center;
        border-radius: 10rem;
        padding: 0px 12rem;
        height: 32rem;
        font-weight: 600;
        font-size: 16rem;
        color: #000;
        margin-bottom: 24rem;

        span {
            width: 8rem;
            height: 8rem;
            border-radius: 50%;
            margin-right: 6rem;
            background: #17cc37;
            ;
        }
    }

    &-desc {
        display: grid;
        grid-template-columns: 1fr 185rem;
        grid-gap: 24rem;

    }

    &-text {
        span {
            font-weight: 500;
            font-size: 14rem;
            color: #a7a8aa;
            line-height: 1;
            font-weight: 500;
            font-size: 16rem;
            line-height: 130%;
            color: #a7a8aa;
        }

        p {
            font-weight: 500;
            font-size: 16rem;
            line-height: 130%;
            color: #000;
            a {
                color: #000;
                &:hover {
                    text-decoration: underline;
                }
                
            }
        }

        &--head {
            grid-column: 2 span;
        }
    }

    &-bottom {
        margin-top: 32rem;
        display: flex;
        justify-content: space-between;

        &-item {
            strong {
                font-weight: 700;
                font-size: 20rem;
                color: #000;
                line-height: 1;
                margin-bottom: 8rem;
                display: block;
            }

            span {
                font-weight: 500;
                font-size: 12rem;
                color: #000;
                line-height: 1;
                display: block;
            }
        }
    }
    &-active {
        width: 51rem;
        height: 31rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        background: #000;
        border-radius: 100rem;
        position: absolute;
        top: 33rem;
        right: 24rem;
        padding: 0 3rem;
        cursor: pointer;
        span {
            width: 27rem;
            height: 27rem;
            border-radius: 50%;
            background: #fff;
        }
    }
}
.promotionsPageNew {
    .userInfo-block-item--location {
        .table-tooltip-info {
            top: 85rem;
        }
    }
}