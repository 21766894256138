.userInfo {
    &-info {

        margin-bottom: 50rem;
    }

    &__title {
        color: #A7A8AA;
        font-size: 20rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 24rem;
        font-family: Italian Plate No2 Expanded, sans-serif;
    }

    &-active {
        margin-bottom: 50rem;
    }

    &-history {
        .userInfo-block {
            pointer-events: none;
            opacity: 0.5;
        }
    }

    &-block {
        border-radius: 16rem;
        background: var(--white, #FFF);
        padding: 24rem 32rem 50rem;
        margin-bottom: 16rem;
        transition: 0.3s;

        &:last-child {
            margin-bottom: 0;
        }

        &-top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 26rem;
        }

        &__title {
            color: #000;
            font-size: 24rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            display: flex;
            align-items: center;
            font-family: Italian Plate No2 Expanded, sans-serif;

            &--Growth {
                width: 8rem;
                height: 8rem;
                background: #17CC37;
                border-radius: 50%;
                margin-right: 10rem;
            }

            &--Trial {
                width: 8rem;
                height: 8rem;
                background: #FFA215;
                border-radius: 50%;
                margin-right: 10rem;
            }
        }

        &-action {
            display: flex;
        }

        &__cancel {
            border-radius: 100rem;
            border: 1rem solid #E5E8EC;
            height: 44rem;
            padding: 0 24rem;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            color: #000;
            text-align: center;
            font-size: 16rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            font-family: Italian Plate No2 Expanded, sans-serif;
        }

        &__save {
            border-radius: 100rem;
            border: 1rem solid #000;
            background: #000;
            height: 44rem;
            padding: 0 24rem;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            color: #fff;
            text-align: center;
            font-size: 16rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-left: 8rem;
            font-family: Italian Plate No2 Expanded, sans-serif;
        }

        &__edit {
            border-radius: 100rem;
            border: 1rem solid #E5E8EC;
            height: 44rem;
            padding: 0 24rem;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            color: #000;
            text-align: center;
            font-size: 16rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            font-family: Italian Plate No2 Expanded, sans-serif;
        }

        &-wrapper {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-column-gap: 50rem;
            grid-row-gap: 34rem;
        }

        &-item {
            display: flex;
            flex-direction: column;

            >span {
                color: #A7A8AA;
                font-size: 14rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-bottom: 10rem;
                font-family: Italian Plate No2 Expanded, sans-serif;
            }

            &-wrapper {
                position: relative;
                width: 100%;
                i {
                    position: absolute;
                    display: block;
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
                    top: 0;
                    left: 0;
                }
            }
            label {
                pointer-events: none;
            }
            .select .select-item{
                pointer-events: none;
            }
            input {
                color: #000;
                font-size: 16rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                border: none;
                padding: 0;
                pointer-events: none;
                padding-bottom: 5rem;
                font-family: Italian Plate No2 Expanded, sans-serif;
                border-bottom: 1rem solid transparent;
                width: 100%;
            }
            p {
                color: #000;
                font-size: 16rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                a {
                    color: #000;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
            &__select {
                pointer-events: none;
                .select {
                    pointer-events: none;
                }
            }
            textarea {
                color: #000;
                font-size: 16rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                border: none;
                padding: 0;
                pointer-events: none;
                padding-bottom: 5rem;
                font-family: Italian Plate No2 Expanded, sans-serif;
                border-bottom: 1rem solid transparent;
            }

            &__phone {
                position: relative;

                img {
                    width: 22.22rem;
                    height: 16rem;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    pointer-events: none;
                }

                input {
                    padding-left: 30rem;
                }
            }

            &-bottom {
                display: flex;

                // align-items: center;
                img {
                    width: 16rem;
                    height: 16rem;
                    margin-right: 8rem;
                }

                p {
                    color: #000;
                    font-size: 16rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    font-family: Italian Plate No2 Expanded, sans-serif;
                }
            }
        }

        &.active {
            filter: drop-shadow(0rem 7rem 41rem rgba(0, 0, 0, 0.08));

            .userInfo-block-item {
                input {
                    pointer-events: all;
                    border-bottom: 1rem solid #000;
                    ;
                }
            }

            .userInfo-block-item-wrapper i {
                display: none;
            }
            .select .select-item{
                pointer-events: all;
            }
        }
    }

    .progress {
        width: 20rem;
        height: 20rem;
        position: relative;
        z-index: 1;
        margin-right: 8rem;

        &-bg {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: -1;
        }
    }


    .table {
        overflow: visible;
        .table-body-cell {
            padding: 16rem 0;
            align-items: center;

            &:first-child {
                padding-top: 16rem;
            }
        }

        .MuiPaper-root {
            padding: 36rem 32rem;
            overflow: visible;
        }

        .MuiTableRow-root:last-child {
            .MuiTableCell-root {
                border: none;
                padding-bottom: 0;
            }
        }
        .MuiTableRow-root:first-child {
            .MuiTableCell-root {
                padding-bottom: 16rem;
            }
        }

        .active-info {
            width: 100%;
            padding-left: 12rem;

            &-top {
                display: flex;
                align-items: center;
                margin-bottom: 6rem;

                strong {
                    color: var(--black, #000);
                    leading-trim: both;
                    text-edge: cap;
                    font-family: Italian Plate No2 Expanded;
                    font-size: 16rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    margin-right: 24rem;

                }

                span {
                    padding: 5rem 12rem;
                    border-radius: 36rem;
                    background: var(--Gray-light, #F2F4F6);
                    color: var(--Gray-dark, #A7A8AA);
                    font-family: Italian Plate No2 Expanded;
                    font-size: 14rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;

                    &.green {
                        background: #D7F8C3;
                        color: #1CAF36;
                    }

                    &.pause-yellow {
                        color: #D8B633;
                        background: rgba(255, 217, 72, 0.11);
                    }
                }
            }

            p {
                color: var(--Gray-dark, #A7A8AA);
                font-family: Italian Plate No2 Expanded;
                font-size: 14rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                display: flex;
                align-items: center;

                span {
                    width: 4rem;
                    height: 4rem;
                    border-radius: 50%;
                    background: #A7A8AA;
                    margin: 0 4rem;
                }
            }
        }

        .MuiTableCell-head {
            padding: 0 0 16rem;
        }
    }
    .table .MuiTableCell-sizeMedium {
        overflow: visible;
    }
    .active-action {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        &-btn {
            border-radius: 10rem;
            border: 1rem solid var(--gray, #F2F4F6);
            width: 34rem;
            height: 34rem;
            display: flex;
            align-items: center;
            cursor: pointer;
            justify-content: center;
            margin-left: 6rem;
            transition: 0.3s;
            position: relative;
            i {
                width: 100%;
                height: 100%;
                position: absolute;

            }
            &:hover {
                background: var(--Gray-light, #F2F4F6);
            }
            i:hover {
                span {
                    display: flex;
                }
            }
            ul {
                top: calc(100% + 6rem);
                right: 0;
                position: absolute;
                z-index: 2;
                border-radius: 8rem;
                border: 1rem solid var(--Gray-light, #F2F4F6);
                background: #FFF;
                box-shadow: 0rem 7rem 41rem -6rem rgba(0, 0, 0, 0.08);
                overflow: hidden;
                li {
                    background: #FFF;
                    width: 180rem;
                    height: 38rem;
                    display: flex;
                    align-items: center;
                    padding: 0 20rem;
                    &:hover {
                        background: var(--Gray-light, #F2F4F6);
                    }
                }
            }

            span {
                position: absolute;
                    top: calc(100% + 14rem);
                    height: 28rem;
                    padding: 0 12rem;
                    display:none;
                    align-items: center;
                    background: #000;
                    color: #FFF;
                    text-overflow: ellipsis;
                    font-family: Italian Plate No2 Expanded;
                    font-size: 14rem;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                    white-space: nowrap;
                    border-radius: 5rem;
                    right: -17rem;
                z-index: 1;
                    &::after {

                        display: inline-block;
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 0 9rem 10rem 9rem;
                        border-color: transparent transparent #000 transparent;
                        position: absolute;
                        content: '';
                        bottom: 100%;
                        right: 23rem;
                    }
            }
        }
    }

    .userInfo-payments {
        &-amount {
            align-items: center;
            color: var(--black, #000);
            font-family: Italian Plate No2 Expanded;
            font-size: 16rem;
            font-style: normal;
            font-weight: 600 !important;
            line-height: 115%;
            text-align: right;

            span {
                color: var(--Gray-dark, #A7A8AA);
                font-weight: 500 !important;
                margin-left: 12rem;
            }
        }

        &-status {
            padding-left: 12rem !important;

            span {
                border-radius: 39rem;

                padding: 8rem 12rem;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                leading-trim: both;
                text-edge: cap;
                font-family: Italian Plate No2 Expanded;
                font-size: 12rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                width: min-content;
                white-space: nowrap;

                &.Refund {
                    background: #F43535;
                    color: #FFF;
                }

                &.Succeeded {
                    background: #D7F8C3;
                    color: #1CAF36;
                }

                &.Canceled {
                    background: rgba(244, 53, 53, 0.12);
                    color: #F43535;
                }

                svg {
                    margin-left: 4rem;
                }
            }
        }

        &-description {
            width: 100%;
            padding-left: 24rem !important;
        }
    }

    .userInfo-method {
        &-card {
            padding-left: 13.5rem !important;
            width: 100%;
            >span {
                color: var(--black, #000);
                leading-trim: both;
                text-edge: cap;
                font-family: Italian Plate No2 Expanded;
                font-size: 16rem;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                display: flex;
                align-items: center;

                i {
                    padding-top: 1rem;
                    margin: 0 6.5rem;
                    display: flex;
                    align-items: center;
                }

                span {
                    margin: 0 1.5rem;
                    width: 4rem;
                    height: 4rem;
                    border-radius: 50%;
                    background: #000;
                }

                .default {
                    border-radius: 36rem;
                    background: var(--Gray-light, #F2F4F6);
                    padding: 5rem 12rem;
                    color: var(--Gray-dark, #A7A8AA);
                    font-family: Italian Plate No2 Expanded;
                    font-size: 14rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

            }
        }
    }
    .userInfo-logs-date {
        width: 230rem;

        padding-right: 30rem;
    }
    .table-admin {
        width: 230rem;
        padding-right: 30rem;
    }
    .table-action {
        width: 100%;
    }
}