.promotions {
    &-block {
        border-radius: 16rem;
        background: var(--white, #FFF);
        padding: 32rem 32rem 36rem;
        display: flex;
        margin-bottom: 40rem;
        &-item {
            width: 180rem;
            display: flex;
            flex-direction: column;
            &:nth-child(2) {
                margin-right: 40rem;
            }
            span {
                color: #A7A8AA;
                font-family: Italian Plate No2 Expanded, sans-serif;
                font-size: 14rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-bottom: 10rem;
                &:last-child {
                    margin-bottom: 0;
                }
            }

            strong {
                color: #000;
                font-family: Italian Plate No2 Expanded, sans-serif;
                font-size: 24rem;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
        }
    }
    .table .MuiTableCell-sizeMedium {
        padding: 26rem 17.5rem 24rem;
        border-right: none;
    }
}