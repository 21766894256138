.newsletter {
    &__title {
        color: #A7A8AA;
        font-family: Italian Plate No2 Expanded, sans-serif;
        font-size: 20rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 24rem;
    }
    .table .table-body-cell:first-child {
        padding-top: 26rem;
    }
    &-wrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 12rem;
        margin-bottom: 40rem;
    }

    &-item {
        border-radius: 16rem;
        background: var(--white, #FFF);
        display: flex;
        padding: 24rem 24rem 34rem;
        cursor: pointer;
        svg {
            width: 42rem;
            min-width: 42rem;
            height: 42rem;
            margin-right: 12rem;
        }
        &-info {
            padding-top: 5rem;
            strong {
                display: block;
                color: #000;
                font-size: 22rem;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin-bottom: 16rem;
            }
            p {
                color: #83868C;
                font-family: Italian Plate No2 Expanded, sans-serif;
                font-size: 16rem;
                font-style: normal;
                font-weight: 500;
                line-height: 120%;
            }
        }
    }
    &-table-delete {
        border-radius: 10rem;
        background: #F43535;
        width: 36rem;
        height: 36rem;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        
        svg {
            width: 18rem;
            height: 18rem;
        }
        transition: 0.3s;
        &:hover {
            background: #CC2C2C;
        }
    }
}