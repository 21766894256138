@import 'normalize.css';

@font-face {
    font-family: 'Italian Plate No2 Expanded';
    src: url('../fonts/ItalianPlateNo2Expanded-Thin.woff2') format('woff2'),
        url('../fonts/ItalianPlateNo2Expanded-Thin.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Italian Plate No2 Expanded';
    src: url('../fonts/ItalianPlateNo2Expanded-Medium.woff2') format('woff2'),
        url('../fonts/ItalianPlateNo2Expanded-Medium.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Italian Plate No2 Expanded';
    src: url('../fonts/ItalianPlateNo2Expanded-Regular.woff2') format('woff2'),
        url('../fonts/ItalianPlateNo2Expanded-Regular.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Italian Plate No2 Expanded';
    src: url('../fonts/ItalianPlateNo2Expanded-Demibold.woff2') format('woff2'),
        url('../fonts/ItalianPlateNo2Expanded-Demibold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Italian Plate No2 Expanded';
    src: url('../fonts/ItalianPlateNo2Expanded-Extralight.woff2') format('woff2'),
        url('../fonts/ItalianPlateNo2Expanded-Extralight.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Italian Plate No2 Expanded';
    src: url('../fonts/ItalianPlateNo2Expanded-Extrabold.woff2') format('woff2'),
        url('../fonts/ItalianPlateNo2Expanded-Extrabold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Italian Plate No2 Expanded';
    src: url('../fonts/ItalianPlateNo2Expanded-Light.woff2') format('woff2'),
        url('../fonts/ItalianPlateNo2Expanded-Light.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Text';
    src: url('../fonts/SFProText-Regular.woff2') format('woff2'),
        url('../fonts/SFProText-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Text';
    src: url('../fonts/SFProText-Semibold.woff2') format('woff2'),
        url('../fonts/SFProText-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}



html {
    margin: 0;
}

html {
    height: 100%;
    scroll-behavior: smooth;
    background: #FFFFFF;
    font-size: calc(100vw/ 1920);

    @media (min-width: 1920px) {
        font-size: 1px;
    }
}

@media (max-width: 991px) {
    html {
        font-size: calc(991px/ 1920);
        overflow-y: hidden;
    }

    body {
        min-width: 991px;
        height: 100vh;
        position: relative;
    }

}

body {
    height: 100%;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
    font-family: 'Italian Plate No2 Expanded';
    font-size: 16px;
    background: #FFFFFF;
    -webkit-tap-highlight-color: transparent;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}


body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
    margin: 0;
    padding: 0;
    font-weight: normal;
}

a {
    text-decoration: none;
}

ol,
ul {
    list-style: none;
}

img {
    max-width: 100%;
    display: block;
}

input,
button,
textarea,
select {
    font: inherit;
}

audio,
canvas,
iframe,
img,
svg,
video,
textarea {
    vertical-align: middle;
}

textarea {
    resize: none;
}

section {
    position: relative;
}

input,
select,
button {
    outline: none;
}

.wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.content {
    flex: 1 0 auto;
    background: #F2F4F6;
    padding: 30rem 0 80rem;
}

.container {
    padding: 0 100rem;
}

.main {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.table {
    overflow: hidden;

    .swiper-slide {
        padding: 0 !important;
        width: auto;
    }

    .swiper-scrollbar {
        background: transparent;
    }

    &-action {
        display: flex;
        align-items: center;

        color: #000;
        font-family: Italian Plate No2 Expanded, sans-serif;
        font-size: 16rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 100%;

        div {
            width: 100%;
            white-space: break-spaces;
        }

        &-red {
            border-radius: 32rem;
            background: #F43535;
            padding: 8rem 14rem;
            color: var(--white, #FFF);
            font-family: Italian Plate No2 Expanded, sans-serif;
            font-size: 16rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-right: 12rem;
        }

        &-gray {
            border-radius: 32rem;
            background: #F2F4F6;
            padding: 8rem 14rem;
            color: #A7A8AA;
            font-family: Italian Plate No2 Expanded, sans-serif;
            font-size: 16rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-right: 12rem;
        }
    }

    &-admin {
        width: 300rem;

        strong {
            overflow: hidden;
            color: #000;
            text-overflow: ellipsis;
            whitespace: nowrap;
            font-family: Italian Plate No2 Expanded, sans-serif;
            font-size: 16rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            display: block;
            margin-bottom: 6rem;
        }

        span {
            color: #A7A8AA;
            font-family: Italian Plate No2 Expanded, sans-serif;
            font-size: 16rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    &-top {
        display: flex;
        align-items: center;
        margin-bottom: 13rem;
        position: relative;
        z-index: 0;

        &__total {
            color: #A7A8AA;
            font-size: 14rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            display: flex;
            align-items: center;
        }

        &-right {
            margin-left: auto;
            display: flex;
            align-items: center;
        }

        &-grid {
            margin-left: 20rem;
            width: 36rem;
            height: 36rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 10rem;
            background: #000;

            svg {
                width: 22rem;
                height: 22rem;
            }
        }

        &-list {
            margin-left: 6rem;
            width: 36rem;
            height: 36rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 10rem;
            background: #fff;

            svg {
                width: 22rem;
                height: 22rem;
            }
        }
    }

    .MuiPaginationItem-root {
        width: 36rem;
        height: 36rem;
        border-radius: 8rem;
        margin: 0 3rem;
        font-family: "Italian Plate No2 Expanded";
        color: #000;
        text-align: center;
        font-size: 16rem;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        min-width: 36rem;
        padding: 0 6rem;
    }

    .MuiPaginationItem-ellipsis {
        width: 19rem;
        min-width: 19rem;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding: 7rem 0;
    }

    .MuiPaginationItem-root.Mui-selected {
        background: var(--white, #FFF);
    }

    .MuiPaginationItem-root.Mui-selected {

        &:hover,
        &:focus {
            background: var(--white, #FFF) !important;
        }

    }

    .MuiTouchRipple-root {
        display: none;
    }

    .MuiPaper-root {
        border-radius: 16rem;
        box-shadow: none;
    }

    .MuiTableCell-root,
    .MuiTableCell-root {
        padding: 24rem;
        color: #A7A8AA;
        font-family: Italian Plate No2 Expanded, sans-serif;
        font-size: 16rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        white-space: nowrap;
        border-color: #F2F4F6;
        border-right: 1rem solid #F2F4F6;

        &:last-child {
            border-right: none;
        }
    }

    .MuiTableCell-sizeMedium {
        max-height: 66rem;
        padding: 26rem 24rem 24rem;
        color: #000;
        font-family: Italian Plate No2 Expanded, sans-serif;
        font-size: 16rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        white-space: nowrap;
        overflow: hidden;
        border-color: #F2F4F6;
        border-right: 1rem solid #F2F4F6;

        &:last-child {
            border-right: none;
        }
    }

    .MuiTableRow-head {
        .MuiTableCell-sizeMedium {
            color: #A7A8AA;
        }
    }

    &-name {
        max-width: 140rem;

        span {
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            display: block;
            text-overflow: ellipsis;
        }


    }

    &-email {
        max-width: 240rem;
        overflow: visible !important;
        position: relative;

        padding: 0 !important;

        &:hover {
            .table-tooltip-info {
                display: flex;
            }
        }
    }

    &-download {
        width: 150rem;
        overflow: visible !important;
        position: relative;

        padding: 0 !important;
       
        .table-tooltip {
            display: flex;
            justify-content: center;
            width: 100%;
        }
        .table-tooltip-info {
            padding-left: 0;
            overflow: hidden;
        }
        &:hover {
            .table-tooltip-info {
                display: flex;
            }
        }
    }

    &-website {
        max-width: 230rem;
        position: relative;
        padding: 0 16rem;
        overflow: visible !important;
        padding: 0 !important;

        &:hover {
            .table-tooltip-info {
                display: flex;
            }
        }

        .table-tooltip {
            padding: 24rem 16rem;
        }
    }

    &-tooltip {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        display: block;
        text-overflow: ellipsis;
        padding: 26rem 24rem 24rem;

        &-info {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            height: 28rem;
            display: none;
            align-items: center;
            padding-left: 10rem;
            z-index: 1;
            background: #000;
            color: white;
            top: -14rem;
            border-radius: 5rem;
            // overflow: hidden;
            font-family: Italian Plate No2 Expanded, sans-serif;
            font-size: 14rem;
            font-style: normal;
            font-weight: 300;
            line-height: normal;

            &:after {
                position: absolute;
                content: '';
                top: calc(100% - 2rem);
                left: 50%;
                transform: translateX(-50%);
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 12rem 10rem 0 10rem;
                border-color: #000000 transparent transparent transparent;
            }

            svg {
                width: 14rem;
                height: 14rem;
                margin-right: 8rem;
            }

            &__btn {
                padding: 0 7rem;
                height: 100%;
                display: flex;
                align-items: center;
                color: var(--white, #FFF);
                font-family: Italian Plate No2 Expanded, sans-serif;
                font-size: 14rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                border-left: 1rem solid #252525;
                transition: 0.3s;
                cursor: pointer;

                &:hover {
                    border-left: 1rem solid #252525;
                    background: #252525;
                }
            }

            &-open {
                margin-left: 10rem;
            }
        }
    }

    &-phone {
        // display: flex!important;
        // align-items: center;
        position: relative;
        padding-left: 46rem !important;
    }

    &-subscription {
        display: flex !important;
        align-items: center;
    }



    &-row {
        &:hover {
            background: var(--white, #FFF);
            box-shadow: 0rem 0rem 30rem 0rem rgba(0, 0, 0, 0.15);
        }

    }

    .icon-os {
        width: 20rem;
        height: 20rem;
        display: block;
        margin: 0 auto;
        background-size: cover !important;
    }

    .icon-flag {
        width: 22.4rem;
        height: 16rem;
        display: block;
        margin-top: 2rem;
        margin-right: 8rem;
        position: absolute;
        left: 16rem;
        top: 50%;
        transform: translateY(-50%);
        background-size: cover;
    }

    .icon-push {
        width: 16rem;
        height: 16rem;
        display: block;
        margin: 0 auto;
        background-size: cover !important;
    }

    .icon-GROWTH,
    .icon-PRO,
    .icon-PLUS,
    .icon-BUNDLE {
        width: 8rem;
        height: 8rem;
        border-radius: 50%;
        background: #17CC37;
        margin-right: 6rem;
        display: block;
    }

    .icon-REFUND {
        width: 8rem;
        height: 8rem;
        border-radius: 50%;
        background: #F43535;
        margin-right: 6rem;
        display: block;
    }

    .icon-STARTER {
        width: 8rem;
        height: 8rem;
        border-radius: 50%;
        background: #17CC37;
        margin-right: 6rem;
        display: block;
    }

    .icon-TRIAL,
    .icon-FREE {
        width: 8rem;
        height: 8rem;
        border-radius: 50%;
        background: #FFA215;
        margin-right: 6rem;
        display: block;
    }

    .icon-FREE {
        width: 8rem;
        height: 8rem;
        border-radius: 50%;
        background: #DDD;
        margin-right: 6rem;
        display: block;
    }

    .icon-DiSPUT {
        width: 8rem;
        height: 8rem;
        border-radius: 50%;
        background: #F43535;
        margin-right: 6rem;
        display: block;
    }

    &-product {
        display: flex;
        align-items: center;

        img {
            min-width: 60rem;
            width: 60rem;
            height: 60rem;
            object-fit: cover;
            border-radius: 9rem;
            border: 1rem solid var(--bg-gray, #F5F5F5);
            margin-right: 12rem;
        }

        &__singleImg {
            img {
                min-width: 60rem;
                width: 60rem;
                height: 60rem;
                object-fit: cover;
                border-radius: 9rem;
                border: 1rem solid var(--bg-gray, #F5F5F5);
            }

        }

        &__criteria {
            width: 90rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        &__short {
            width: 220rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        &__img {
            min-width: 60rem;
            width: 60rem;
            height: 60rem;
            margin-right: 12rem;
            border-radius: 9rem;
            background: var(--bg-gray, #F5F5F5);
            display: flex;
            align-items: center;
            justify-content: center;
            color: #000;
            text-align: center;
            leading-trim: both;
            text-edge: cap;
            font-family: Italian Plate No2 Expanded, sans-serif;
            font-size: 15rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
        }

        p {
            height: 38rem;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        color: #000;
        font-family: Italian Plate No2 Expanded,
        sans-serif;
        font-size: 16rem;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;

        white-space: pre-wrap;
        margin-right: 0;

        &-wrap {
            width: 380rem;
        }
    }

    &-status {
        display: flex;
        align-items: center;
    }



    .table-head-cell {
        border-bottom: none;
        padding-bottom: 16rem;
        border-right: none;
    }

    .table-body-cell {
        border-right: none;

        &:first-child {
            padding-top: 9rem;
        }
    }

    &-switch {
        width: 42rem;
        height: 25rem;
        padding: 2rem;
        background: #000;
        border-radius: 20rem;
        display: flex;
        justify-content: flex-end;

        &-circle {
            width: 21rem;
            height: 21rem;
            border-radius: 50%;
            background: #fff;
        }

        &-off {
            background: #E5E8EC;
            justify-content: flex-start;
        }
    }

    &-order-id {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        >div {
            margin-right: 0 !important;
        }
    }

    &-bottom {
        margin-top: 16rem;
        display: flex;
        justify-content: flex-end;

        .pagination {
            margin-left: auto;
        }
    }
}

.icon-green {
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    background: #17CC37;
    margin-right: 6rem;
    display: block;
}

.icon-blue {
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    background: #2555FF;
    margin-right: 6rem;
    display: block;
}

.icon-yellow {
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    background: #F5C724;
    margin-right: 6rem;
    display: block;
}

.icon-orange {
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    background: #FFA215;
    margin-right: 6rem;
    display: block;
}

.icon-gray {
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    background: #DDD;
    margin-right: 6rem;
    display: block;
}

.icon-red {
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    background: #F43535;
    margin-right: 6rem;
    display: block;
}

.pagination {
    display: flex;
    align-items: center;
    margin-left: auto;

    &__page {
        margin-left: 6rem;
        padding: 0 9rem;
        color: #A7A8AA;
        font-size: 14rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-right: 24rem;
        font-family: Italian Plate No2 Expanded, sans-serif;
    }
}

.sidebars-tab__title {
    color: #A7A8AA;
    font-family: Italian Plate No2 Expanded, sans-serif;
    font-size: 20rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.notification {
    &-top {
        color: #000;
        font-family: Italian Plate No2 Expanded, sans-serif;
        font-size: 16rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 53rem;
    }
}


.spin-loader {
    position: absolute;
    width: 26rem;
    height: 26rem;
    top: 50%;
    left: 50%;
    margin-left: -13rem;
    margin-top: -13rem;
}

/* iOS Loader */
.ios-loader .bar {
    width: 10%;
    height: 26%;
    background: #fff;
    position: absolute;
    left: 44.5%;
    top: 37%;

    border-radius: 50rem;
    box-shadow: 0 0 3rem rgba(0, 0, 0, 0.2);
    opacity: 0;
    animation: fade 1s linear infinite;
}

.table .MuiPaper-root {
    background: #fff;

    &::-webkit-scrollbar {
        width: 4rem;
        height: 4rem;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10rem;
        background: rgba(213, 218, 224, 0.87);
    }
}


@keyframes fade {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.ios-loader .bar1 {
    transform: rotate(-315deg) translate(0rem, -142%);
    animation-delay: -0.875s;
}

.ios-loader .bar2 {
    transform: rotate(-270deg) translate(0rem, -142%);
    animation-delay: -0.75s;
}

.ios-loader .bar3 {
    transform: rotate(-225deg) translate(0rem, -142%);
    animation-delay: -0.625s;
}

.ios-loader .bar4 {
    transform: rotate(-180deg) translate(0rem, -142%);
    animation-delay: -0.5s;
}

.ios-loader .bar5 {
    transform: rotate(-135deg) translate(0rem, -142%);
    animation-delay: -0.375s;
}

.ios-loader .bar6 {
    transform: rotate(-90deg) translate(0rem, -142%);
    animation-delay: -0.25s;
}

.ios-loader .bar7 {
    transform: rotate(-45deg) translate(0rem, -142%);
    animation-delay: -0.125s;
}

.ios-loader .bar8 {
    transform: rotate(0deg) translate(0rem, -142%);
    animation-delay: 0s;
}

.empty {
    padding-top: 285rem;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
        color: var(--gray-dark, #A7A8AA);
        text-align: center;
        font-family: Italian Plate No2 Expanded;
        font-size: 28rem;
        font-style: normal;
        font-weight: 700;
        line-height: 125%;
    }
}

.checkbox {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: 28rem;

    input {
        opacity: 0;
        width: 0;
        height: 0;
        visibility: none;
        pointer-events: none;
        user-select: none;
        position: absolute;
        z-index: -100;

        &:checked+.checkbox__icon svg {
            opacity: 1;
        }
    }

    &__icon {
        width: 20rem;
        height: 20rem;
        border-radius: 5rem;
        background: #FFF;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 8rem;

        svg {
            opacity: 0;
            width: 10rem;
            height: 10rem;
        }
    }

    >span {
        color: #000 !important;
        ;
        font-family: Italian Plate No2 Expanded;
        font-size: 14rem;
        font-style: normal;
        font-weight: 500 !important;
        line-height: normal;
        margin-right: 0 !important;
    }
}