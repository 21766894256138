.videos-modal {
    background: #fff;
    border-radius: 16px;

    &-header {
        display: grid;
        grid-template-columns: 693rem 1fr;
        grid-gap: 32rem;
        border-bottom: 1px solid #f2f4f6;
        align-items: center;
        padding: 12rem 23rem 9rem;

        &__title {
            font-weight: 500;
            font-size: 16rem;
            color: #a7a8aa;
        }
    }

    &-body {
        display: grid;
        grid-template-columns: 693rem 1fr;
        grid-gap: 32rem;

        padding: 24rem 23rem 12rem;
    }

    &-footer {
        display: grid;
        grid-template-columns: 801rem 1fr;
        grid-gap: 32rem;

        padding: 12rem 23rem 44rem;

        .table {
            display: flex;
            justify-content: center;
        }

        .pagination {
            margin: 0 auto;
        }

        .MuiPaginationItem-previousNext {
            background: #f2f4f6;
            opacity: 1;
        }

        .Mui-disabled {
            opacity: 1 !important;
        }

        .modal-action {
            justify-content: flex-end;
        }
    }

    &-info {
        &-item {
            margin-bottom: 50rem;

            span {
                font-weight: 500;
                font-size: 14rem;
                color: #a7a8aa;
                margin-bottom: 10rem;
                display: block;
            }

            p {
                font-weight: 500;
                font-size: 16rem;
                line-height: 130%;
                color: #000;
            }

        }

        &-wrap {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 51rem;
            &--3 {
                grid-template-columns: repeat(3, 1fr);
            }
            &--5 {
                grid-template-columns: repeat(5, 1fr);
            }
        }

        &-stat {
            display: flex;
            justify-content: space-between;
            margin-bottom: 47rem;

            &-item {
                white-space: nowrap;

                span {
                    font-weight: 600;
                    font-size: 16rem;
                    color: #000;
                    display: block;
                    margin-bottom: 10rem;
                    line-height: 1;
                }


                h4 {
                    font-weight: 700;
                    font-size: 32rem;
                    color: #000;
                    margin-bottom: 4rem;

                }

                strong {
                    font-weight: 500;
                    font-size: 16rem;
                    color: #a7a8aa;
                    display: block;
                    line-height: 1;
                }

                position: relative;

                &:after {
                    position: absolute;
                    content: '';
                    width: 1rem;
                    height: 43rem;
                    background: #cad4dd;
                    top: 50%;
                    transform: translateY(-50%);
                    right: -80rem;
                }
            }
        }

        &-link {
            border: 1px solid #f2f4f6;
            border-radius: 8rem;
            padding: 0 15rem;
            width: 518rem;
            height: 61rem;
            display: flex;
            align-items: center;
            h4 {
                font-weight: 500;
                font-size: 14rem;
                line-height: 1;
                color: #a7a8aa;
                margin-bottom: 8rem;
            }
            a {
                font-weight: 500;
                font-size: 14rem;
                text-decoration: underline;
                text-decoration-skip-ink: none;
                color: #2555ff;
                width: 431rem;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
            }
            .btn {
                background: #000;
                border-radius: 100px;
                padding: 0px 10rem;
                height: 21rem;
                font-weight: 600;
                font-size: 12rem;
                text-align: center;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: auto;
                cursor: pointer;
            }
        }
        display: flex;
        flex-direction: column;
        height: calc(100% - 23rem);
        &-top {
            flex: 1;
        }
        &-bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}