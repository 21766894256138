.analytics {
    &__title {
        a {
            opacity: 0.18;
            color: var(--black, #000);
            leading-trim: both;
            text-edge: cap;
            font-family: Italian Plate No2 Expanded, sans-serif;
            font-size: 24rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-right: 28rem;
            &.active {
                opacity: 1;
            }
        }
    
        margin-bottom: 32rem;
    }
    &-top {
        display: flex;
        align-items: center;
        margin-bottom: 12rem;
        span {
            color: var(--gray-dark, #A7A8AA);
            font-family: Italian Plate No2 Expanded, sans-serif;
            font-size: 14rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-right: 20rem;
        }
        &-date {
            &-wrap {
                position: relative;
                z-index: 2;
            }
            position: relative;
            border-radius: 8rem;
            background: var(--white, #FFF);
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 12rem;
            height: 36rem;
            width: 178rem;
            &-icon {
                width: 16rem;
                height: 16rem;
                display: flex;
                align-items: center;
                justify-content: center;
                svg {
                    width: 100%;
                    height: 100%;
                }
            }
            &-arrow {
                width: 12rem;
                height: 8rem;
                display: flex;
                align-items: center;
                justify-content: center;
                svg {
                    width: 100%;
                    height: 100%;
                }
            }
            &-text {
                display: flex;
                color: var(--black, var(--black, #000));
                text-align: center;
                leading-trim: both;
                text-edge: cap;
                font-family: Italian Plate No2 Expanded, sans-serif;
                font-size: 16rem;
                font-style: normal;
                font-weight: 500;
                line-height: 130%; /* 20.8rem */
                div {
                    margin-left: 3rem;
                }
            }
            &-block {
                position: absolute;
                &:after {
                    position: absolute;
                    content: '';
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='19' viewBox='0 0 12 19' fill='none'%3E%3Cpath d='M0 0H12C9 9 8 19 8 19H4C4 19 3 9 0 0Z' fill='white'/%3E%3C/svg%3E");
                    width: 12rem;
                    height: 19rem;
                    bottom: 100%;
                    left: 50%;
                    transform: translateX(-50%);
                }
                top: calc(100% + 19rem);
                right: -83rem;
                border-radius: 10rem;
   
                    background: var(--white, #FFF);
                    box-shadow: 0rem 4rem 33rem -12rem rgba(16, 16, 16, 0.12);
                    padding: 24rem 22rem;
                .react-datepicker {
                    border-radius: 0;
                    border: none;
                    background: transparent;
                    box-shadow: 0;
                    padding:0;
                }
                .react-datepicker-year-header {
                    background: transparent;
                    padding: 0;
                    border: none;
                    color: var(--black, #000);
                    leading-trim: both;
                    text-edge: cap;
                    font-family: Italian Plate No2 Expanded, sans-serif;
                    font-size: 18rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    margin-bottom: 24rem;
                }
                .react-datepicker__navigation--previous {
                    width: 16rem;
                    height: 16rem;
                    margin: 0;
                    top: 3rem;
                    left: 17rem;
                }
                .react-datepicker__navigation-icon--previous {
                    width: 16rem;
                    height: 16rem;
                    margin: 0;
                   position: static;
                    &::before {
                        border: none;
                        width: 16rem;
                        height: 16rem;
                        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M10 13L5 8L10 3' stroke='%23A7A8AA' stroke-width='1.9' stroke-linecap='round'/%3E%3C/svg%3E");
                        background-size: cover;
                        transform: none;
                        right: 0;
                        top: 0;
                    }
                }
                .react-datepicker__navigation--next {
                    width: 16rem;
                    height: 16rem;
                    margin: 0;
                    top: 3rem;
                    right: 17rem;
                }
                .react-datepicker__navigation-icon--next {
                    width: 16rem;
                    height: 16rem;
                    margin: 0;
                   position: static;
                    &::before {
                        border: none;
                        width: 16rem;
                        height: 16rem;
                        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M6 13L11 8L6 3' stroke='%23A7A8AA' stroke-width='1.9' stroke-linecap='round'/%3E%3C/svg%3E");
                        background-size: cover;
                        transform: none;
                        right: 0;
                        left: 0;
                        bottom: 0;
                        top: 0;
                    }
                }
                .react-datepicker__month-text {
                    width: 54rem!important;;
                    height: 36rem;
                    margin: 0!important;
                    display: flex!important;;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 6rem!important;
                    color: var(--black, var(--black, #000));
                    text-align: center;
                    
                    font-family: Italian Plate No2 Expanded, sans-serif;
                    font-size: 16rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
                .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
                    margin: 0!important;
                    margin-bottom: 6rem!important;
                    color: var(--black, var(--black, #000));
                    text-align: center;
                    font-family: Italian Plate No2 Expanded, sans-serif;
                    font-size: 16rem;
                    font-style: normal;
                    font-weight: 500;
                }
                .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
                    background-color: var(--black, #000)!important;
                    color: var(--white, #FFF);
                    border-radius: 0;
                }
                .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
                    background-color: var(--black, #000)!important;
                    color: var(--white, #FFF);
                    border-radius: 0;
                }
                .react-datepicker__month-wrapper {
                    display: flex;
                }
                .react-datepicker__month-text--selected {
                    background-color: var(--black, #000)!important;
                    color: var(--white, #FFF);
                    border-radius: 0;
                
                }
                .react-datepicker__header {
                    background: transparent;
                    padding-top: 0;
                }
                .react-datepicker__month-text--keyboard-selected {
                    background-color: transparent;
                }
                
                .react-datepicker__month-text--selecting-range-end, .react-datepicker__month-text--range-end {
                    border-top-right-radius: 8rem!important;;
                    border-bottom-right-radius: 8rem!important;;
                }
                .react-datepicker__month-text--selecting-range-start, .react-datepicker__month-text--range-start {
                    border-top-left-radius: 8rem!important;;
                    border-bottom-left-radius: 8rem!important;;
                }
                .react-datepicker__day--range-start{
                    border-top-left-radius: 8rem!important;;
                    border-bottom-left-radius: 8rem!important;;
                }
                .react-datepicker__day--range-end {
                    border-top-right-radius: 8rem!important;;
                    border-bottom-right-radius: 8rem!important;;
                }

                .react-datepicker__month {
                    margin: 0;
                }
                .react-datepicker__month-text--disabled {
                    pointer-events: none;
                    color: var(--gray-dark, #A7A8AA);
                }
                .react-datepicker__day--disabled {
                    pointer-events: none;
                    color: var(--gray-dark, #A7A8AA);
                }
                .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
                    width: 27rem;
                    height: 27rem;
                    line-height: 27rem;
                    font-size: 16rem;
          
                }
                .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
                    font-size: 16rem;
                }

            }
            &__btn {
                margin: 5rem auto 0;
                border-radius: 100rem;
                background: var(--black, #000);
                display: flex;
                align-items: center;
                justify-content: center;
                height: 48rem;
                width: 100%;
                color: var(--white, #FFF);
                text-align: center;
                font-family: Italian Plate No2 Expanded, sans-serif;
                font-size: 16rem;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                cursor: pointer;
            }
        }
    }
    &-block {
        border-radius: 16rem;
        background: var(--white, #FFF);
        padding: 32rem;
        margin-bottom: 40rem;
        &-top {
            display: flex;
            margin-bottom: 50rem;
        }   
        &-item {
            width: 212rem;
            display: flex;
            flex-direction: column;
            padding-left: 12rem;
            position: relative;
            margin-right: 50rem;
            span {
                color: var(--gray-dark, #A7A8AA);
                font-family: Italian Plate No2 Expanded, sans-serif;
                font-size: 14rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-bottom: 12rem;
            }
            strong {
                color: var(--black, #000);
                font-family: Italian Plate No2 Expanded, sans-serif;
                font-size: 32rem;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
            i {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 2rem;
                height: 53rem;
            }
        }
        &-line {
            width: 100%;
            height: 321rem;
            margin-bottom: 40rem;
            canvas {
                width: 100%;
            }
        }
        &-visibility {
            display: flex;
            align-items: center;
            span {
                color: var(--gray-dark, #A7A8AA);
                font-family: Italian Plate No2 Expanded, sans-serif;
                font-size: 14rem;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin-right: 40rem;
            }
            label {
                display: flex;
                align-items: center;
                cursor: pointer;
                margin-right: 45rem;
                input {
                    z-index: -1;
                    visibility: hidden;
                    width: 0;
                    height: 0;
                    opacity: 0;
                    pointer-events: none;
                    &:checked + .analytics-block-visibility__icon {
                        span {
                            background: transparent;
                            svg {
                                opacity: 1;
                            }
                            
                        }
                    }

                }
                p {
                    color: var(--black, #000);
                    font-family: Italian Plate No2 Expanded, sans-serif;
                    font-size: 14rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

            }
            &__icon {
                border-radius: 5rem;
                background: #586BD8;
                padding: 1rem;
                width: 20rem;
                height: 20rem;
                margin-right: 6rem;
                span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                    background: #fff;
                    border-radius: 4rem;
                    svg {
                        width: 10rem;
                        height: 10rem;
                        opacity: 0;
                    }
                }
            }
        }
    }
    h3 {
        color: var(--gray-dark, #A7A8AA);
        leading-trim: both;
        text-edge: cap;
        font-family: Italian Plate No2 Expanded, sans-serif;
        font-size: 20rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 24rem;
    }
    &-wrapper {
        display: flex;
        flex-direction: column-reverse;
    }
    &-month {
        border-radius: 16rem;
        background: var(--white, #FFF);
        margin-bottom: 12rem;
        padding: 32rem;
        h4 {
            color: var(--black, #000);
            font-family: Italian Plate No2 Expanded, sans-serif;
            font-size: 28rem;
            font-style: normal;
            font-weight: 700;
            line-height: 125%;
            margin-bottom: 36rem;
        }
        &-wrapper {
            display: flex;
            justify-content: space-between;
            margin-bottom: 34rem;
            &.active {
                .analytics-month-item {
                    opacity: 0.52;
                    &.hovered {
                        opacity: 1;
                    }
                }

            }
        }
        &-item {
            width: 180rem;
            display: flex;
            flex-direction: column;
            transition: 0.3s;
            strong {
                color: var(--black, var(--black, #000));
                font-family: Italian Plate No2 Expanded, sans-serif;
                font-size: 24rem;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin-bottom: 12rem;
                display: flex;
                align-items: flex-start;
                span {
                    color: #17CC37;
                    font-family: Italian Plate No2 Expanded, sans-serif;
                    font-size: 14rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    line-height: 1.8;
                    margin-left: 8rem;
                    &.red {
                        color: #CC2C2C;
                    }
                }
            }
            span {
                color: var(--gray-dark, #A7A8AA);

                font-family: Italian Plate No2 Expanded, sans-serif;
                font-size: 14rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                display: flex;
                align-items: center;
                i {
                    width: 6rem;
                    height: 6rem;
                    border-radius: 50%;
                    margin-right: 6rem;
                    display: block;
                }
            }
          

        }
        &-line {
            display: flex;
            &-item {
                border-right: 2rem solid var(--white, #FFF);
                height: 26rem;
                position: relative;
                z-index: 0;
                &:last-child {
                    border-top-right-radius: 8rem;
                    border-bottom-right-radius: 8rem;
                }
                &:first-child {
                    border-top-left-radius: 8rem;
                    border-bottom-left-radius: 8rem;
                }
                &:after {
                    position: absolute;
                    content: '';
                    width: calc(100%);
                    top: -6rem;
                    
                    height: calc(100% + 12rem);
                    background: inherit;
                    opacity: 0;
                    transition: 0.3s;
                    z-index: -1;
                }
                &-info {
                    display: none;
                    position: absolute;
                    bottom: calc(100% + 25rem);
                    left: 50%;
                    transform: translateX(-50%);
                    border-radius: 6rem;
                    background: var(--white, #FFF);
                    box-shadow: 0rem 4rem 33rem -12rem rgba(16, 16, 16, 0.12);
                    &:after {
                        width: 12rem;
                        height: 25rem;
                        position: absolute;
                        content: '';
                        left: 50%;
                        transform: translateX(-50%);
                        top: 100%;
                        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='25' viewBox='0 0 13 25' fill='none'%3E%3Cpath d='M0.5 0H12.5C9.5 9 8.5 25 8.5 25H4.5C4.5 25 3.5 9 0.5 0Z' fill='white'/%3E%3C/svg%3E");
                    }
                    > span {
                        padding: 12rem 16rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-bottom: 1rem solid #F2F4F6;
                        color: var(--black, var(--black, #000));
                        font-family: Italian Plate No2 Expanded, sans-serif;
                        font-size: 14rem;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        white-space: nowrap;
                        i {
                            width: 6rem;
                            height: 6rem;
                            border-radius: 50%;
                            margin-right: 6rem;
                        }
                        span {
                            margin-left: 5rem;
                            color: #17CC37;
                            &.red {
                                color: #CC2C2C;
                            }
                        }
                    }
                    ul {
                        padding: 12rem 16rem;
                        li {
                            color: var(--black, #A7A8AA);

                            font-family: Italian Plate No2 Expanded, sans-serif;
                            font-size: 14rem;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            margin-bottom: 10rem;
                            display: flex;
                            &:last-child {
                                margin-bottom: 0;
                            }
                            strong {
                                color: var(--black, var(--black, #000));
                                font-weight: 700;
                                margin-left: 5rem;
                            }
                        }
                    }
                }
                &:hover {
                    &:after {
                        opacity: 0.28;
                    }
                    .analytics-month-line-item-info {
                        display: block;
                    }
                }
            }
        }
    }
}
.custom-tooltip {
    border-radius: 6rem;
    background: var(--white, #FFF);
    color: var(--black, var(--black, #000));
    box-shadow: 0rem 4rem 33rem -12rem rgba(16, 16, 16, 0.12);
    &:after {
        width: 12rem;
        height: 25rem;
        position: absolute;
        content: '';
        left: 50%;
        transform: translateX(-50%);
        top: 100%;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='25' viewBox='0 0 13 25' fill='none'%3E%3Cpath d='M0.5 0H12.5C9.5 9 8.5 25 8.5 25H4.5C4.5 25 3.5 9 0.5 0Z' fill='white'/%3E%3C/svg%3E");
    }
    table {
        padding: 0;
        border-collapse: collapse
    }
    thead {
        border-bottom: 1rem solid #F2F4F6;
        tr {
            display: flex;
            justify-content: flex-start;
            padding: 12rem 16rem;
            
        }
        th {
            display: flex;
            align-items: center;
            color: var(--black, var(--black, #000));
            font-family: Italian Plate No2 Expanded, sans-serif;
            font-size: 14rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
        
    }
    tbody {
        td {
            color: var(--black, #A7A8AA);
            font-family: Italian Plate No2 Expanded, sans-serif;
            font-size: 14rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            padding: 12rem 16rem;
            span {
                
            font-family: Italian Plate No2 Expanded, sans-serif;
            font-size: 14rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
                color: var(--black, var(--black, #000));
            }
        }
    }
}

.overview-top {
    .analytics-top-date {
        width: 123rem;
    }
}
.overview-block {
    display: grid;
    grid-template-columns: repeat(3, calc((100% - 36rem * 2) / 3));
    grid-gap: 36rem;
    &-top {
        margin-bottom: 48rem;
        span {
            display: flex;
            align-items: center;
            margin-bottom: 12rem;
            p {
                color: #404040;
                font-family: Italian Plate No2 Expanded;
                font-size: 14rem;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin-right: 12rem;
            }
            i {
                color: #17CC37;
                font-family: Italian Plate No2 Expanded;
                font-size: 14rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }
        strong {
            display: flex;
            align-items: flex-end;
            i {
                color: var(--black, #000);
                font-family: Italian Plate No2 Expanded;
                font-size: 24rem;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin-right: 12rem;
            }
            p {
                color: var(--gray-dark, #A7A8AA);
                font-family: Italian Plate No2 Expanded;
                font-size: 14rem;
                font-style: normal;
                font-weight: 500;
                line-height: 140%;
            }
        }
    }
    &-item {
        background: #fff;
        border-radius: 16rem;
        padding: 32rem 32rem 48rem;
        &-chart {
            height: 218rem;
        }
        h5 {
            color: #404040;
            font-family: Italian Plate No2 Expanded;
            font-size: 14rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal; 
            margin-bottom: 16rem;
        }
        .analytics-month-line {
            pointer-events: none;
            margin-bottom: 32rem;
        }
        ul {
            li {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 18rem;
                border-bottom: 1rem solid #F2F4F6;
                margin-bottom: 18rem;
                &:last-child {
                    margin-bottom: 0;
                    padding-bottom: 0;
                    border: none;
                }
                span {
                    display: flex;
                    align-items: center;
                    color: #404040;
                    font-family: Italian Plate No2 Expanded;
                    font-size: 14rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    i {
                        width: 10rem;
                        height: 10rem;
                        border-radius: 50%;
                        margin-right: 6rem;
                    }
                }
                strong {
                    color: #404040;
                    text-align: right;
                    font-family: Italian Plate No2 Expanded;
                    font-size: 14rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }
            }
        }
    }
}