.promotionsNew-stat {
    &-item {
        width: 180rem;
        position: relative;
        margin-right: 80rem;
        &:after {
            position: absolute;
            content: '';
            width: 1px;
            height: 43rem;
            background: #cad4dd;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }
        &:last-child {
            margin-right: 0;
            &:after {
                display: none;
            }
        }
        strong {
            font-weight: 700;
            font-size: 32rem;
            color: #000;
            margin-bottom: 8rem;
            font-family: "Italian Plate No2 Expanded", sans-serif;
            line-height: 1;
            display: block;
        }
        span {
            font-weight: 500;
            font-size: 16rem;
            color: #a7a8aa;
            display: block;
            font-family: "Italian Plate No2 Expanded", sans-serif;
            line-height: 1;
        }

        
    }

}