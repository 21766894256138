.products-creatives {
    display: grid;
    grid-template-columns: repeat(3 ,1fr);
    grid-gap: 10rem;
    .creatives-item__img {
        aspect-ratio: 1/1;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .creatives-item__ai {
        display: flex;
        span{
            margin-right: 4rem;
        }
    }
}