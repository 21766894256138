.website {
    &-block {
        margin-top: 24rem;
        display: flex;
        align-items: center;
        padding: 38rem 32rem;
        border-radius: 16rem;
        background: var(--white, #FFF);
        margin-bottom: 40rem;

        &-item {
            margin-right: 80rem;
            &__email {
                cursor: pointer;
            }
            span {
                display: block;
                color: #A7A8AA;
                font-family: Italian Plate No2 Expanded, sans-serif;
                font-size: 14rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-bottom: 10rem;
            }

            strong {
                display: flex;
                align-items: center;
                color: #000;
                font-family: Italian Plate No2 Expanded, sans-serif;
                font-size: 24rem;
                font-style: normal;
                font-weight: 600;
                line-height: normal;

                img {
                    width: 25rem;
                    margin-right: 10rem;
                }
            }
        }

        &-action {
            display: flex;
            margin-left: auto;
        }

        &__regenerate {
            border-radius: 100rem;
            border: 1rem solid #E5E8EC;
            padding: 14rem 24rem;
            color: #000;
            text-align: center;
            font-family: Italian Plate No2 Expanded, sans-serif;
            font-size: 16rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-right: 10rem;
            cursor: pointer;
        }

        &__delete {
            border-radius: 100rem;
            border: 1rem solid #F43535;
            padding: 14rem 24rem;
            color: #F43535;
            text-align: center;
            font-family: Italian Plate No2 Expanded, sans-serif;
            font-size: 16rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            cursor: pointer;
        }
    }

    &-uniqueness {
        margin-top: 24rem;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 10rem;

        &-item {
            border-radius: 14rem;
            background: var(--white, #FFF);
            padding: 24rem 32rem 22rem;

            strong {
                display: block;
                color: #000;
                font-family: Italian Plate No2 Expanded, sans-serif;
                font-size: 16rem;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin-bottom: 12rem;
            }

            span {
                display: flex;
                align-items: center;
                color: #000;
                font-family: Italian Plate No2 Expanded, sans-serif;
                font-size: 14rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                svg {
                    width: 16rem;
                    height: 16rem;
                    margin-right: 12rem;
                }
                .icon-green {
                    margin-right: 12rem;
                }
            }
        }
    }
}