.select {
    display: flex;
    color: #000;
    font-family: Italian Plate No2 Expanded, sans-serif;
    font-size: 14rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    align-items: center;
    position: relative;
    &-label {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }
    .css-13cymwt-control, .css-t3ipsp-control  {
        min-height: 0rem!important;
        padding: 0 !important;
        border: none !important;
        background: transparent !important;
        box-shadow: none !important;
        &:hover {
            min-height: auto;
            padding: 0;
            border: none;
            background: transparent;
        }
    }
    .select-item {
        cursor: pointer;
        pointer-events: all;
        // padding-left: 52rem;
    }
    .css-1fdsijx-ValueContainer {
        padding: 0;
        padding-right: 6rem;
    }

    .css-qbdosj-Input {
        padding: 0;
    }



    &-chart {
        margin-left: 16rem;
        color: var(--gray-dark, #A7A8AA);
        font-size: 14rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        .select-item {
            background: #fff;
            padding: 10rem 12rem;
            margin-left: 16rem;
            border-radius: 8rem;
        }
    }
}
