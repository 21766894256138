.notification {
    max-height: 100vh;
    overflow-y: auto;
    padding: 31rem 40rem;

 

    &__title {
        color: #A7A8AA;
        font-family: Italian Plate No2 Expanded, sans-serif;
        font-size: 21rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 25rem;
    }

    &-wrapper {
        display: grid;
        grid-template-columns: calc(100% - 393rem - 35rem) 393rem;
        grid-gap: 35rem;
    }

    &-form {
        >label {
            display: block;
            margin-bottom: 20rem;

            >span {
                color: #A7A8AA;
                font-family: Italian Plate No2 Expanded, sans-serif;
                font-size: 14rem;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                display: block;
                margin-bottom: 10rem;
            }

            input {
                width: 100%;
                height: 46rem;
                padding: 0 16rem;
                border-radius: 9rem;
                border: 1rem solid #E5E8EC;
                color: #000;
                font-family: Italian Plate No2 Expanded, sans-serif;
                font-size: 16rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                &::placeholder {
                    color: #A7A8AA;
                }
            }

            textarea {
                width: 100%;
                height: 46rem;
                padding: 15rem 16rem;
                border-radius: 9rem;
                border: 1rem solid #E5E8EC;
                color: #000;
                font-family: Italian Plate No2 Expanded, sans-serif;
                font-size: 16rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                outline: none;

                &::placeholder {
                    color: #A7A8AA;
                }
            }

            .input {
                position: relative;

                i {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 16rem;
                    color: #A7A8AA;
                    font-family: Italian Plate No2 Expanded, sans-serif;
                    font-size: 14rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
        }



        .css-1u9des2-indicatorSeparator {
            display: none;
        }

        .radio {
            margin-bottom: 33rem;

            >span {
                color: #A7A8AA;
                font-family: Italian Plate No2 Expanded, sans-serif;
                font-size: 14rem;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin-bottom: 10rem;
                display: block;
            }

            &-wrap {
                display: flex;

                label {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    margin-right: 50rem;

                    >input {
                        width: 0;
                        height: 0;
                        opacity: 0;
                        visibility: hidden;
                        position: absolute;
                        z-index: -10;
                        pointer-events: none;

                        &:checked+.radio__icon {
                            span {
                                opacity: 1;
                            }
                        }
                    }

                    color: #000;
                    font-family: Italian Plate No2 Expanded, sans-serif;
                    font-size: 14rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;

                    a {
                        color: #0085FF;
                        text-decoration: underline;
                        margin-left: 5rem;
                    }
                }
            }

            &__icon {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 20rem;
                height: 20rem;
                border-radius: 50%;
                border: 1rem solid #000;
                margin-right: 8rem;

                span {
                    background: #000;
                    width: 10rem;
                    height: 10rem;
                    border-radius: 50%;
                    opacity: 0;
                }
            }



        }

        &__btn {
            width: 100%;
            height: 54rem;
            border-radius: 64rem;
            background: #000;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            outline: none;
            cursor: pointer;
            color: var(--white, #FFF);
            text-align: center;
            font-family: Italian Plate No2 Expanded, sans-serif;
            font-size: 18rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
    }

    &-preview {
        &-lock {
            width: 100%;
            position: relative;
            z-index: 1;

            &__screen {
                width: 100%;

                img {
                    width: 100%;
                }
            }

            &__wallpaper {
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;

                img {
                    width: 100%;
                    border-radius: 16rem;
                }
            }

            &-block {
                width: calc(100% - 32rem);
                position: absolute;
                left: 16rem;
                bottom: 187rem;
                display: flex;
                align-items: center;
                border-radius: 22rem;
                background: #fff;
                padding: 14rem 13rem;

                img {
                    width: 37rem;
                    height: 37rem;
                    margin-right: 10rem;
                    object-fit: cover;
                }

                strong {
                    color: var(--label-color-light-primary, #000);
                    font-feature-settings: 'case' on;
                    font-family: 'SF Pro Text';
                    font-size: 15.611rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 19.514rem;
                    /* 125% */
                    letter-spacing: -0.878rem;
                    display: block;
                }

                span {
                    color: var(--label-color-light-primary, #000);
                    font-family: 'SF Pro Text';
                    font-size: 12.684rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1.2;
                    /* 138.462% */
                    letter-spacing: -0.076rem;
                    display: block;
                }
            }
        }

        &-message {
            position: relative;
            border-radius: 16rem;
            overflow: hidden;

            &__screen {
                img {
                    width: 100%;
                    border-radius: 16rem;
                }
            }

            &__statusBar {
                position: absolute;
                width: 100%;
                top: 0;
                left: 0;

                img {
                    width: 100%;
                }
            }
        }

        &-box {
            position: absolute;
            width: 100%;
            bottom: 0;
            left: 0;
            border-radius: 14.672rem 14.672rem 0rem 0rem;
            background: var(--white, #FFF);
            padding: 25rem 25rem 40rem;

            &-top {
                display: flex;
                align-items: center;
                margin-bottom: 26rem;

                img {
                    width: 50rem;
                    height: 50rem;
                    margin-right: 12rem;
                }

                strong {
                    color: #000;
                    font-family: Italian Plate No2 Expanded, sans-serif;
                    font-size: 16.768rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    display: block;
                    margin-bottom: 8rem;
                }

                span {
                    color: #797979;
                    font-family: Italian Plate No2 Expanded, sans-serif;
                    font-size: 12.576rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%;
                    display: block;
                }
            }

            &__title {
                color: var(--black, #000);
                font-family: Italian Plate No2 Expanded, sans-serif;
                font-size: 25.152rem;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                margin-bottom: 18rem;
            }

            .wmde-markdown {
                color: #000;
                font-family: Italian Plate No2 Expanded, sans-serif;
                font-size: 16.768rem;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
            }

            &__btn {
                border-radius: 104.8rem;
                background: #000;
                padding: 20rem 52rem;
                color: var(--white, #FFF);
                text-align: center;
                font-family: Italian Plate No2 Expanded, sans-serif;
                font-size: 16.768rem;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                white-space: nowrap;
                width: min-content;
                margin-top: 26rem;
            }
        }
        .swiper-pagination {
            position: static;
  
        }
        .swiper-pagination-bullet  {
            width: 8rem;
            height: 8rem;
            background: #000;
            opacity: 0.12;
        }
        .swiper-pagination-bullet-active {
            opacity: 1;
        }
        .swiper-slide {
            padding: 0;
        }
    }
}