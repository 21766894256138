.ai {
    .sidebars-tab__title {
        margin-bottom: 24rem;
    }
    .userInfo-block {
        padding: 36rem 32rem;
    }
    .userInfo-block-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        grid-gap: 0rem;
    }

    .userInfo-block-item {
        span {
            color: #A7A8AA;
            font-family: Italian Plate No2 Expanded, sans-serif;
            font-size: 14rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
        input {
            font-size: 24rem;
            font-weight: 600;
        }
    }
    .userInfo-block-item__phone img {
        top: calc(50% - 2.5rem)
    }
    .select .select-item {
        margin-left: 0;
    }
}