.products-ads {
    height: 100%;
    .promotionsNew-AllPromotions-info {
        width: 176rem;
    }
    .table .MuiTableCell-sizeMedium {
        padding: 24rem 9rem 16rem;
        border-right: none;
        span {
            text-align: left;
            display: flex;
        }
    }
    .promotionsNew-AllPromotions-date {
        text-align: left;
    }
    .table-row:hover {
        box-shadow: none;
    }
    .promotionsNew-AllPromotions-info p {
        text-align: left;
    }
}