.search-input {
    position: relative;
    margin-right: 12rem;
    height: 48rem;
    input {
        width: 450rem;
        height: 48rem;
        border-radius: 8rem;
        background: #F2F4F6;
        padding-left: 43rem;
        color: #000;
        font-size: 14rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        box-shadow: none;
        border: none;
        &::placeholder {
            color: #A7A8AA;
        }
    }
    svg {
        position: absolute;
        left: 15rem;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
        width: 18rem;
        height: 18rem;
    }
}
